import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IPriceRent } from 'app/shared/model/rentcar/price-rent.model';
import { getEntities as getPriceRents } from 'app/entities/rentcar/price-rent/price-rent.reducer';
import { IDModel } from 'app/shared/model/rentcar/d-model.model';
import { getEntities as getDModels } from 'app/entities/rentcar/d-model/d-model.reducer';
import { IDPolicy } from 'app/shared/model/rentcar/d-policy.model';
import { getEntities as getDPolicies } from 'app/entities/rentcar/d-policy/d-policy.reducer';
import { IDPricePenalty } from 'app/shared/model/rentcar/d-price-penalty.model';
import { getEntities as getDPricePenalties } from 'app/entities/rentcar/d-price-penalty/d-price-penalty.reducer';
import { IDFeeService } from 'app/shared/model/rentcar/d-fee-service.model';
import { getEntities as getDFeeServices } from 'app/entities/rentcar/d-fee-service/d-fee-service.reducer';
import { IDFeeInsurance } from 'app/shared/model/rentcar/d-fee-insurance.model';
import { getEntities as getDFeeInsurances } from 'app/entities/rentcar/d-fee-insurance/d-fee-insurance.reducer';
import { IDPromotion } from 'app/shared/model/rentcar/d-promotion.model';
import { getEntities as getDPromotions } from 'app/entities/rentcar/d-promotion/d-promotion.reducer';
import { IOwnerCar } from 'app/shared/model/rentcar/owner-car.model';
import { EnumGearBox } from 'app/shared/model/enumerations/enum-gear-box.model';
import { EnumSeat } from 'app/shared/model/enumerations/enum-seat.model';
import { getEntity, updateEntity, createEntity, reset } from './owner-car.reducer';

export const OwnerCarUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const priceRents = useAppSelector(state => state.rentcargw.priceRent.entities);
  const dModels = useAppSelector(state => state.rentcargw.dModel.entities);
  const dPolicies = useAppSelector(state => state.rentcargw.dPolicy.entities);
  const dPricePenalties = useAppSelector(state => state.rentcargw.dPricePenalty.entities);
  const dFeeServices = useAppSelector(state => state.rentcargw.dFeeService.entities);
  const dFeeInsurances = useAppSelector(state => state.rentcargw.dFeeInsurance.entities);
  const dPromotions = useAppSelector(state => state.rentcargw.dPromotion.entities);
  const ownerCarEntity = useAppSelector(state => state.rentcargw.ownerCar.entity);
  const loading = useAppSelector(state => state.rentcargw.ownerCar.loading);
  const updating = useAppSelector(state => state.rentcargw.ownerCar.updating);
  const updateSuccess = useAppSelector(state => state.rentcargw.ownerCar.updateSuccess);
  const enumGearBoxValues = Object.keys(EnumGearBox);
  const enumSeatValues = Object.keys(EnumSeat);

  const handleClose = () => {
    navigate('/owner-car' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getPriceRents({}));
    dispatch(getDModels({}));
    dispatch(getDPolicies({}));
    dispatch(getDPricePenalties({}));
    dispatch(getDFeeServices({}));
    dispatch(getDFeeInsurances({}));
    dispatch(getDPromotions({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.createDate = convertDateTimeToServer(values.createDate);
    values.modifyDate = convertDateTimeToServer(values.modifyDate);

    const entity = {
      ...ownerCarEntity,
      ...values,
      pricerent: priceRents.find(it => it.id.toString() === values.pricerent.toString()),
      dmodel: dModels.find(it => it.id.toString() === values.dmodel.toString()),
      policy: dPolicies.find(it => it.id.toString() === values.policy.toString()),
      dpricepernalty: dPricePenalties.find(it => it.id.toString() === values.dpricepernalty.toString()),
      dfeeservice: dFeeServices.find(it => it.id.toString() === values.dfeeservice.toString()),
      dfeeinsurance: dFeeInsurances.find(it => it.id.toString() === values.dfeeinsurance.toString()),
      dpromotion: dPromotions.find(it => it.id.toString() === values.dpromotion.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          createDate: displayDefaultDateTime(),
          modifyDate: displayDefaultDateTime(),
        }
      : {
          gearEnum: 'MANUAL',
          seatEnum: 'C4',
          ...ownerCarEntity,
          createDate: convertDateTimeFromServer(ownerCarEntity.createDate),
          modifyDate: convertDateTimeFromServer(ownerCarEntity.modifyDate),
          pricerent: ownerCarEntity?.pricerent?.id,
          dmodel: ownerCarEntity?.dmodel?.id,
          policy: ownerCarEntity?.policy?.id,
          dpricepernalty: ownerCarEntity?.dpricepernalty?.id,
          dfeeservice: ownerCarEntity?.dfeeservice?.id,
          dfeeinsurance: ownerCarEntity?.dfeeinsurance?.id,
          dpromotion: ownerCarEntity?.dpromotion?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="rentcargwApp.rentcarOwnerCar.home.createOrEditLabel" data-cy="OwnerCarCreateUpdateHeading">
            <Translate contentKey="rentcargwApp.rentcarOwnerCar.home.createOrEditLabel">Create or edit a OwnerCar</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="owner-car-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('rentcargwApp.rentcarOwnerCar.numberPlate')}
                id="owner-car-numberPlate"
                name="numberPlate"
                data-cy="numberPlate"
                type="text"
                validate={{
                  maxLength: { value: 20, message: translate('entity.validation.maxlength', { max: 20 }) },
                }}
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarOwnerCar.usernameOwner')}
                id="owner-car-usernameOwner"
                name="usernameOwner"
                data-cy="usernameOwner"
                type="text"
                validate={{
                  maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                }}
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarOwnerCar.userIdOwner')}
                id="owner-car-userIdOwner"
                name="userIdOwner"
                data-cy="userIdOwner"
                type="text"
                validate={{
                  maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                }}
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarOwnerCar.introText')}
                id="owner-car-introText"
                name="introText"
                data-cy="introText"
                type="text"
                validate={{
                  maxLength: { value: 1000, message: translate('entity.validation.maxlength', { max: 1000 }) },
                }}
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarOwnerCar.note')}
                id="owner-car-note"
                name="note"
                data-cy="note"
                type="text"
                validate={{
                  maxLength: { value: 1000, message: translate('entity.validation.maxlength', { max: 1000 }) },
                }}
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarOwnerCar.colorCar')}
                id="owner-car-colorCar"
                name="colorCar"
                data-cy="colorCar"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarOwnerCar.gearEnum')}
                id="owner-car-gearEnum"
                name="gearEnum"
                data-cy="gearEnum"
                type="select"
              >
                {enumGearBoxValues.map(enumGearBox => (
                  <option value={enumGearBox} key={enumGearBox}>
                    {translate('rentcargwApp.EnumGearBox.' + enumGearBox)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('rentcargwApp.rentcarOwnerCar.dieselType')}
                id="owner-car-dieselType"
                name="dieselType"
                data-cy="dieselType"
                type="text"
                validate={{
                  maxLength: { value: 20, message: translate('entity.validation.maxlength', { max: 20 }) },
                }}
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarOwnerCar.seatEnum')}
                id="owner-car-seatEnum"
                name="seatEnum"
                data-cy="seatEnum"
                type="select"
              >
                {enumSeatValues.map(enumSeat => (
                  <option value={enumSeat} key={enumSeat}>
                    {translate('rentcargwApp.EnumSeat.' + enumSeat)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('rentcargwApp.rentcarOwnerCar.yearOfManufactury')}
                id="owner-car-yearOfManufactury"
                name="yearOfManufactury"
                data-cy="yearOfManufactury"
                type="text"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarOwnerCar.addressOfCar')}
                id="owner-car-addressOfCar"
                name="addressOfCar"
                data-cy="addressOfCar"
                type="text"
                validate={{
                  maxLength: { value: 500, message: translate('entity.validation.maxlength', { max: 500 }) },
                }}
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarOwnerCar.xcoordinate')}
                id="owner-car-xcoordinate"
                name="xcoordinate"
                data-cy="xcoordinate"
                type="text"
                validate={{
                  maxLength: { value: 150, message: translate('entity.validation.maxlength', { max: 150 }) },
                }}
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarOwnerCar.ycoordinate')}
                id="owner-car-ycoordinate"
                name="ycoordinate"
                data-cy="ycoordinate"
                type="text"
                validate={{
                  maxLength: { value: 150, message: translate('entity.validation.maxlength', { max: 150 }) },
                }}
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarOwnerCar.codeCancel')}
                id="owner-car-codeCancel"
                name="codeCancel"
                data-cy="codeCancel"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarOwnerCar.noteCancel')}
                id="owner-car-noteCancel"
                name="noteCancel"
                data-cy="noteCancel"
                type="text"
                validate={{
                  maxLength: { value: 500, message: translate('entity.validation.maxlength', { max: 500 }) },
                }}
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarOwnerCar.isActive')}
                id="owner-car-isActive"
                name="isActive"
                data-cy="isActive"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 20, message: translate('entity.validation.maxlength', { max: 20 }) },
                }}
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarOwnerCar.createDate')}
                id="owner-car-createDate"
                name="createDate"
                data-cy="createDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarOwnerCar.createBy')}
                id="owner-car-createBy"
                name="createBy"
                data-cy="createBy"
                type="text"
                validate={{
                  maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                }}
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarOwnerCar.modifyDate')}
                id="owner-car-modifyDate"
                name="modifyDate"
                data-cy="modifyDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarOwnerCar.modifyBy')}
                id="owner-car-modifyBy"
                name="modifyBy"
                data-cy="modifyBy"
                type="text"
                validate={{
                  maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                }}
              />
              <ValidatedField
                id="owner-car-pricerent"
                name="pricerent"
                data-cy="pricerent"
                label={translate('rentcargwApp.rentcarOwnerCar.pricerent')}
                type="select"
              >
                <option value="" key="0" />
                {priceRents
                  ? priceRents.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="owner-car-dmodel"
                name="dmodel"
                data-cy="dmodel"
                label={translate('rentcargwApp.rentcarOwnerCar.dmodel')}
                type="select"
              >
                <option value="" key="0" />
                {dModels
                  ? dModels.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="owner-car-policy"
                name="policy"
                data-cy="policy"
                label={translate('rentcargwApp.rentcarOwnerCar.policy')}
                type="select"
              >
                <option value="" key="0" />
                {dPolicies
                  ? dPolicies.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="owner-car-dpricepernalty"
                name="dpricepernalty"
                data-cy="dpricepernalty"
                label={translate('rentcargwApp.rentcarOwnerCar.dpricepernalty')}
                type="select"
              >
                <option value="" key="0" />
                {dPricePenalties
                  ? dPricePenalties.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="owner-car-dfeeservice"
                name="dfeeservice"
                data-cy="dfeeservice"
                label={translate('rentcargwApp.rentcarOwnerCar.dfeeservice')}
                type="select"
              >
                <option value="" key="0" />
                {dFeeServices
                  ? dFeeServices.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="owner-car-dfeeinsurance"
                name="dfeeinsurance"
                data-cy="dfeeinsurance"
                label={translate('rentcargwApp.rentcarOwnerCar.dfeeinsurance')}
                type="select"
              >
                <option value="" key="0" />
                {dFeeInsurances
                  ? dFeeInsurances.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="owner-car-dpromotion"
                name="dpromotion"
                data-cy="dpromotion"
                label={translate('rentcargwApp.rentcarOwnerCar.dpromotion')}
                type="select"
              >
                <option value="" key="0" />
                {dPromotions
                  ? dPromotions.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/owner-car" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default OwnerCarUpdate;
