import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import DModel from './d-model';
import DModelDetail from './d-model-detail';
import DModelUpdate from './d-model-update';
import DModelDeleteDialog from './d-model-delete-dialog';

const DModelRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<DModel />} />
    <Route path="new" element={<DModelUpdate />} />
    <Route path=":id">
      <Route index element={<DModelDetail />} />
      <Route path="edit" element={<DModelUpdate />} />
      <Route path="delete" element={<DModelDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default DModelRoutes;
