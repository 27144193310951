import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, byteSize, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './notification-type.reducer';

export const NotificationTypeDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const notificationTypeEntity = useAppSelector(state => state.rentcargw.notificationType.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="notificationTypeDetailsHeading">
          <Translate contentKey="rentcargwApp.rentcarNotificationType.detail.title">NotificationType</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{notificationTypeEntity.id}</dd>
          <dt>
            <span id="code">
              <Translate contentKey="rentcargwApp.rentcarNotificationType.code">Code</Translate>
            </span>
          </dt>
          <dd>{notificationTypeEntity.code}</dd>
          <dt>
            <span id="title">
              <Translate contentKey="rentcargwApp.rentcarNotificationType.title">Title</Translate>
            </span>
          </dt>
          <dd>{notificationTypeEntity.title}</dd>
          <dt>
            <span id="type">
              <Translate contentKey="rentcargwApp.rentcarNotificationType.type">Type</Translate>
            </span>
          </dt>
          <dd>{notificationTypeEntity.type}</dd>
          <dt>
            <span id="key">
              <Translate contentKey="rentcargwApp.rentcarNotificationType.key">Key</Translate>
            </span>
          </dt>
          <dd>{notificationTypeEntity.key}</dd>
          <dt>
            <span id="className">
              <Translate contentKey="rentcargwApp.rentcarNotificationType.className">Class Name</Translate>
            </span>
          </dt>
          <dd>{notificationTypeEntity.className}</dd>
          <dt>
            <span id="methodName">
              <Translate contentKey="rentcargwApp.rentcarNotificationType.methodName">Method Name</Translate>
            </span>
          </dt>
          <dd>{notificationTypeEntity.methodName}</dd>
          <dt>
            <span id="subjectEmail">
              <Translate contentKey="rentcargwApp.rentcarNotificationType.subjectEmail">Subject Email</Translate>
            </span>
          </dt>
          <dd>{notificationTypeEntity.subjectEmail}</dd>
          <dt>
            <span id="bodyEmail">
              <Translate contentKey="rentcargwApp.rentcarNotificationType.bodyEmail">Body Email</Translate>
            </span>
          </dt>
          <dd>{notificationTypeEntity.bodyEmail}</dd>
          <dt>
            <span id="titleNoti">
              <Translate contentKey="rentcargwApp.rentcarNotificationType.titleNoti">Title Noti</Translate>
            </span>
          </dt>
          <dd>{notificationTypeEntity.titleNoti}</dd>
          <dt>
            <span id="bodyNoti">
              <Translate contentKey="rentcargwApp.rentcarNotificationType.bodyNoti">Body Noti</Translate>
            </span>
          </dt>
          <dd>{notificationTypeEntity.bodyNoti}</dd>
          <dt>
            <span id="titleNotiApp">
              <Translate contentKey="rentcargwApp.rentcarNotificationType.titleNotiApp">Title Noti App</Translate>
            </span>
          </dt>
          <dd>{notificationTypeEntity.titleNotiApp}</dd>
          <dt>
            <span id="subtitleNotiApp">
              <Translate contentKey="rentcargwApp.rentcarNotificationType.subtitleNotiApp">Subtitle Noti App</Translate>
            </span>
          </dt>
          <dd>{notificationTypeEntity.subtitleNotiApp}</dd>
          <dt>
            <span id="bodyNotiApp">
              <Translate contentKey="rentcargwApp.rentcarNotificationType.bodyNotiApp">Body Noti App</Translate>
            </span>
          </dt>
          <dd>{notificationTypeEntity.bodyNotiApp}</dd>
          <dt>
            <span id="isActive">
              <Translate contentKey="rentcargwApp.rentcarNotificationType.isActive">Is Active</Translate>
            </span>
          </dt>
          <dd>{notificationTypeEntity.isActive}</dd>
          <dt>
            <span id="createDate">
              <Translate contentKey="rentcargwApp.rentcarNotificationType.createDate">Create Date</Translate>
            </span>
          </dt>
          <dd>
            {notificationTypeEntity.createDate ? (
              <TextFormat value={notificationTypeEntity.createDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="createBy">
              <Translate contentKey="rentcargwApp.rentcarNotificationType.createBy">Create By</Translate>
            </span>
          </dt>
          <dd>{notificationTypeEntity.createBy}</dd>
          <dt>
            <span id="modifyDate">
              <Translate contentKey="rentcargwApp.rentcarNotificationType.modifyDate">Modify Date</Translate>
            </span>
          </dt>
          <dd>
            {notificationTypeEntity.modifyDate ? (
              <TextFormat value={notificationTypeEntity.modifyDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="modifyBy">
              <Translate contentKey="rentcargwApp.rentcarNotificationType.modifyBy">Modify By</Translate>
            </span>
          </dt>
          <dd>{notificationTypeEntity.modifyBy}</dd>
        </dl>
        <Button tag={Link} to="/notification-type" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/notification-type/${notificationTypeEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default NotificationTypeDetail;
