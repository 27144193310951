import dayjs from 'dayjs';
import { ITripCar } from 'app/shared/model/rentcar/trip-car.model';

export interface ITripFeatureExtra {
  id?: number;
  codeExtra?: string | null;
  description?: string | null;
  data?: string | null;
  createDate?: string | null;
  createBy?: string | null;
  modifyDate?: string | null;
  modifyBy?: string | null;
  tripcar?: ITripCar | null;
}

export const defaultValue: Readonly<ITripFeatureExtra> = {};
