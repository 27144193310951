import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import PushNotiHistory from './push-noti-history';
import PushNotiHistoryDetail from './push-noti-history-detail';
import PushNotiHistoryUpdate from './push-noti-history-update';
import PushNotiHistoryDeleteDialog from './push-noti-history-delete-dialog';

const PushNotiHistoryRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<PushNotiHistory />} />
    <Route path="new" element={<PushNotiHistoryUpdate />} />
    <Route path=":id">
      <Route index element={<PushNotiHistoryDetail />} />
      <Route path="edit" element={<PushNotiHistoryUpdate />} />
      <Route path="delete" element={<PushNotiHistoryDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default PushNotiHistoryRoutes;
