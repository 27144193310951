import dayjs from 'dayjs';
import { IOwnerCar } from 'app/shared/model/rentcar/owner-car.model';

export interface IFeeExtra {
  id?: number;
  codeExtra?: string | null;
  description?: string | null;
  typeFee?: string | null;
  amount?: number | null;
  createDate?: string | null;
  createBy?: string | null;
  modifyDate?: string | null;
  modifyBy?: string | null;
  onwer?: IOwnerCar | null;
}

export const defaultValue: Readonly<IFeeExtra> = {};
