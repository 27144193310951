import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { Translate, TextFormat, getSortState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IOwnerCar } from 'app/shared/model/rentcar/owner-car.model';
import { getEntities } from './owner-car.reducer';

export const OwnerCar = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );

  const ownerCarList = useAppSelector(state => state.rentcargw.ownerCar.entities);
  const loading = useAppSelector(state => state.rentcargw.ownerCar.loading);
  const totalItems = useAppSelector(state => state.rentcargw.ownerCar.totalItems);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  return (
    <div>
      <h2 id="owner-car-heading" data-cy="OwnerCarHeading">
        <Translate contentKey="rentcargwApp.rentcarOwnerCar.home.title">Owner Cars</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="rentcargwApp.rentcarOwnerCar.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link to="/owner-car/new" className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="rentcargwApp.rentcarOwnerCar.home.createLabel">Create new Owner Car</Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        {ownerCarList && ownerCarList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="rentcargwApp.rentcarOwnerCar.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('numberPlate')}>
                  <Translate contentKey="rentcargwApp.rentcarOwnerCar.numberPlate">Number Plate</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('usernameOwner')}>
                  <Translate contentKey="rentcargwApp.rentcarOwnerCar.usernameOwner">Username Owner</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('userIdOwner')}>
                  <Translate contentKey="rentcargwApp.rentcarOwnerCar.userIdOwner">User Id Owner</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('introText')}>
                  <Translate contentKey="rentcargwApp.rentcarOwnerCar.introText">Intro Text</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('note')}>
                  <Translate contentKey="rentcargwApp.rentcarOwnerCar.note">Note</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('colorCar')}>
                  <Translate contentKey="rentcargwApp.rentcarOwnerCar.colorCar">Color Car</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('gearEnum')}>
                  <Translate contentKey="rentcargwApp.rentcarOwnerCar.gearEnum">Gear Enum</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('dieselType')}>
                  <Translate contentKey="rentcargwApp.rentcarOwnerCar.dieselType">Diesel Type</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('seatEnum')}>
                  <Translate contentKey="rentcargwApp.rentcarOwnerCar.seatEnum">Seat Enum</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('yearOfManufactury')}>
                  <Translate contentKey="rentcargwApp.rentcarOwnerCar.yearOfManufactury">Year Of Manufactury</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('addressOfCar')}>
                  <Translate contentKey="rentcargwApp.rentcarOwnerCar.addressOfCar">Address Of Car</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('xcoordinate')}>
                  <Translate contentKey="rentcargwApp.rentcarOwnerCar.xcoordinate">Xcoordinate</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('ycoordinate')}>
                  <Translate contentKey="rentcargwApp.rentcarOwnerCar.ycoordinate">Ycoordinate</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('codeCancel')}>
                  <Translate contentKey="rentcargwApp.rentcarOwnerCar.codeCancel">Code Cancel</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('noteCancel')}>
                  <Translate contentKey="rentcargwApp.rentcarOwnerCar.noteCancel">Note Cancel</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('isActive')}>
                  <Translate contentKey="rentcargwApp.rentcarOwnerCar.isActive">Is Active</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('createDate')}>
                  <Translate contentKey="rentcargwApp.rentcarOwnerCar.createDate">Create Date</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('createBy')}>
                  <Translate contentKey="rentcargwApp.rentcarOwnerCar.createBy">Create By</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('modifyDate')}>
                  <Translate contentKey="rentcargwApp.rentcarOwnerCar.modifyDate">Modify Date</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('modifyBy')}>
                  <Translate contentKey="rentcargwApp.rentcarOwnerCar.modifyBy">Modify By</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="rentcargwApp.rentcarOwnerCar.pricerent">Pricerent</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="rentcargwApp.rentcarOwnerCar.dmodel">Dmodel</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="rentcargwApp.rentcarOwnerCar.policy">Policy</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="rentcargwApp.rentcarOwnerCar.dpricepernalty">Dpricepernalty</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="rentcargwApp.rentcarOwnerCar.dfeeservice">Dfeeservice</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="rentcargwApp.rentcarOwnerCar.dfeeinsurance">Dfeeinsurance</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="rentcargwApp.rentcarOwnerCar.dpromotion">Dpromotion</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {ownerCarList.map((ownerCar, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`/owner-car/${ownerCar.id}`} color="link" size="sm">
                      {ownerCar.id}
                    </Button>
                  </td>
                  <td>{ownerCar.numberPlate}</td>
                  <td>{ownerCar.usernameOwner}</td>
                  <td>{ownerCar.userIdOwner}</td>
                  <td>{ownerCar.introText}</td>
                  <td>{ownerCar.note}</td>
                  <td>{ownerCar.colorCar}</td>
                  <td>
                    <Translate contentKey={`rentcargwApp.EnumGearBox.${ownerCar.gearEnum}`} />
                  </td>
                  <td>{ownerCar.dieselType}</td>
                  <td>
                    <Translate contentKey={`rentcargwApp.EnumSeat.${ownerCar.seatEnum}`} />
                  </td>
                  <td>{ownerCar.yearOfManufactury}</td>
                  <td>{ownerCar.addressOfCar}</td>
                  <td>{ownerCar.xcoordinate}</td>
                  <td>{ownerCar.ycoordinate}</td>
                  <td>{ownerCar.codeCancel}</td>
                  <td>{ownerCar.noteCancel}</td>
                  <td>{ownerCar.isActive}</td>
                  <td>{ownerCar.createDate ? <TextFormat type="date" value={ownerCar.createDate} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>{ownerCar.createBy}</td>
                  <td>{ownerCar.modifyDate ? <TextFormat type="date" value={ownerCar.modifyDate} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>{ownerCar.modifyBy}</td>
                  <td>{ownerCar.pricerent ? <Link to={`/price-rent/${ownerCar.pricerent.id}`}>{ownerCar.pricerent.id}</Link> : ''}</td>
                  <td>{ownerCar.dmodel ? <Link to={`/d-model/${ownerCar.dmodel.id}`}>{ownerCar.dmodel.id}</Link> : ''}</td>
                  <td>{ownerCar.policy ? <Link to={`/d-policy/${ownerCar.policy.id}`}>{ownerCar.policy.id}</Link> : ''}</td>
                  <td>
                    {ownerCar.dpricepernalty ? (
                      <Link to={`/d-price-penalty/${ownerCar.dpricepernalty.id}`}>{ownerCar.dpricepernalty.id}</Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {ownerCar.dfeeservice ? <Link to={`/d-fee-service/${ownerCar.dfeeservice.id}`}>{ownerCar.dfeeservice.id}</Link> : ''}
                  </td>
                  <td>
                    {ownerCar.dfeeinsurance ? (
                      <Link to={`/d-fee-insurance/${ownerCar.dfeeinsurance.id}`}>{ownerCar.dfeeinsurance.id}</Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>{ownerCar.dpromotion ? <Link to={`/d-promotion/${ownerCar.dpromotion.id}`}>{ownerCar.dpromotion.id}</Link> : ''}</td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`/owner-car/${ownerCar.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/owner-car/${ownerCar.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/owner-car/${ownerCar.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="rentcargwApp.rentcarOwnerCar.home.notFound">No Owner Cars found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={ownerCarList && ownerCarList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default OwnerCar;
