import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import RefferalUserForRenter from './refferal-user-for-renter';
import RefferalUserForRenterDetail from './refferal-user-for-renter-detail';
import RefferalUserForRenterUpdate from './refferal-user-for-renter-update';
import RefferalUserForRenterDeleteDialog from './refferal-user-for-renter-delete-dialog';

const RefferalUserForRenterRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<RefferalUserForRenter />} />
    <Route path="new" element={<RefferalUserForRenterUpdate />} />
    <Route path=":id">
      <Route index element={<RefferalUserForRenterDetail />} />
      <Route path="edit" element={<RefferalUserForRenterUpdate />} />
      <Route path="delete" element={<RefferalUserForRenterDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default RefferalUserForRenterRoutes;
