import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import TripFeatureExtra from './trip-feature-extra';
import TripFeatureExtraDetail from './trip-feature-extra-detail';
import TripFeatureExtraUpdate from './trip-feature-extra-update';
import TripFeatureExtraDeleteDialog from './trip-feature-extra-delete-dialog';

const TripFeatureExtraRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<TripFeatureExtra />} />
    <Route path="new" element={<TripFeatureExtraUpdate />} />
    <Route path=":id">
      <Route index element={<TripFeatureExtraDetail />} />
      <Route path="edit" element={<TripFeatureExtraUpdate />} />
      <Route path="delete" element={<TripFeatureExtraDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default TripFeatureExtraRoutes;
