import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, byteSize, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './push-noti-history.reducer';

export const PushNotiHistoryDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const pushNotiHistoryEntity = useAppSelector(state => state.rentcargw.pushNotiHistory.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="pushNotiHistoryDetailsHeading">
          <Translate contentKey="rentcargwApp.rentcarPushNotiHistory.detail.title">PushNotiHistory</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{pushNotiHistoryEntity.id}</dd>
          <dt>
            <span id="userName">
              <Translate contentKey="rentcargwApp.rentcarPushNotiHistory.userName">User Name</Translate>
            </span>
          </dt>
          <dd>{pushNotiHistoryEntity.userName}</dd>
          <dt>
            <span id="className">
              <Translate contentKey="rentcargwApp.rentcarPushNotiHistory.className">Class Name</Translate>
            </span>
          </dt>
          <dd>{pushNotiHistoryEntity.className}</dd>
          <dt>
            <span id="methodName">
              <Translate contentKey="rentcargwApp.rentcarPushNotiHistory.methodName">Method Name</Translate>
            </span>
          </dt>
          <dd>{pushNotiHistoryEntity.methodName}</dd>
          <dt>
            <span id="dataRequest">
              <Translate contentKey="rentcargwApp.rentcarPushNotiHistory.dataRequest">Data Request</Translate>
            </span>
          </dt>
          <dd>{pushNotiHistoryEntity.dataRequest}</dd>
          <dt>
            <span id="dataResponse">
              <Translate contentKey="rentcargwApp.rentcarPushNotiHistory.dataResponse">Data Response</Translate>
            </span>
          </dt>
          <dd>{pushNotiHistoryEntity.dataResponse}</dd>
          <dt>
            <span id="status">
              <Translate contentKey="rentcargwApp.rentcarPushNotiHistory.status">Status</Translate>
            </span>
          </dt>
          <dd>{pushNotiHistoryEntity.status}</dd>
          <dt>
            <span id="createDate">
              <Translate contentKey="rentcargwApp.rentcarPushNotiHistory.createDate">Create Date</Translate>
            </span>
          </dt>
          <dd>
            {pushNotiHistoryEntity.createDate ? (
              <TextFormat value={pushNotiHistoryEntity.createDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="createBy">
              <Translate contentKey="rentcargwApp.rentcarPushNotiHistory.createBy">Create By</Translate>
            </span>
          </dt>
          <dd>{pushNotiHistoryEntity.createBy}</dd>
          <dt>
            <span id="modifyDate">
              <Translate contentKey="rentcargwApp.rentcarPushNotiHistory.modifyDate">Modify Date</Translate>
            </span>
          </dt>
          <dd>
            {pushNotiHistoryEntity.modifyDate ? (
              <TextFormat value={pushNotiHistoryEntity.modifyDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="modifyBy">
              <Translate contentKey="rentcargwApp.rentcarPushNotiHistory.modifyBy">Modify By</Translate>
            </span>
          </dt>
          <dd>{pushNotiHistoryEntity.modifyBy}</dd>
        </dl>
        <Button tag={Link} to="/push-noti-history" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/push-noti-history/${pushNotiHistoryEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default PushNotiHistoryDetail;
