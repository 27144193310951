import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import DPolicy from './d-policy';
import DPolicyDetail from './d-policy-detail';
import DPolicyUpdate from './d-policy-update';
import DPolicyDeleteDialog from './d-policy-delete-dialog';

const DPolicyRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<DPolicy />} />
    <Route path="new" element={<DPolicyUpdate />} />
    <Route path=":id">
      <Route index element={<DPolicyDetail />} />
      <Route path="edit" element={<DPolicyUpdate />} />
      <Route path="delete" element={<DPolicyDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default DPolicyRoutes;
