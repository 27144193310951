import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import NotificationType from './notification-type';
import NotificationTypeDetail from './notification-type-detail';
import NotificationTypeUpdate from './notification-type-update';
import NotificationTypeDeleteDialog from './notification-type-delete-dialog';

const NotificationTypeRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<NotificationType />} />
    <Route path="new" element={<NotificationTypeUpdate />} />
    <Route path=":id">
      <Route index element={<NotificationTypeDetail />} />
      <Route path="edit" element={<NotificationTypeUpdate />} />
      <Route path="delete" element={<NotificationTypeDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default NotificationTypeRoutes;
