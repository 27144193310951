import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import NotificationKeyUser from './notification-key-user';
import NotificationKeyUserDetail from './notification-key-user-detail';
import NotificationKeyUserUpdate from './notification-key-user-update';
import NotificationKeyUserDeleteDialog from './notification-key-user-delete-dialog';

const NotificationKeyUserRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<NotificationKeyUser />} />
    <Route path="new" element={<NotificationKeyUserUpdate />} />
    <Route path=":id">
      <Route index element={<NotificationKeyUserDetail />} />
      <Route path="edit" element={<NotificationKeyUserUpdate />} />
      <Route path="delete" element={<NotificationKeyUserDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default NotificationKeyUserRoutes;
