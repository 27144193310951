import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './trip-car.reducer';

export const TripCarDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const tripCarEntity = useAppSelector(state => state.rentcargw.tripCar.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="tripCarDetailsHeading">
          <Translate contentKey="rentcargwApp.rentcarTripCar.detail.title">TripCar</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{tripCarEntity.id}</dd>
          <dt>
            <span id="codeTrip">
              <Translate contentKey="rentcargwApp.rentcarTripCar.codeTrip">Code Trip</Translate>
            </span>
          </dt>
          <dd>{tripCarEntity.codeTrip}</dd>
          <dt>
            <span id="numberPlate">
              <Translate contentKey="rentcargwApp.rentcarTripCar.numberPlate">Number Plate</Translate>
            </span>
          </dt>
          <dd>{tripCarEntity.numberPlate}</dd>
          <dt>
            <span id="usernameRenter">
              <Translate contentKey="rentcargwApp.rentcarTripCar.usernameRenter">Username Renter</Translate>
            </span>
          </dt>
          <dd>{tripCarEntity.usernameRenter}</dd>
          <dt>
            <span id="usernameOwner">
              <Translate contentKey="rentcargwApp.rentcarTripCar.usernameOwner">Username Owner</Translate>
            </span>
          </dt>
          <dd>{tripCarEntity.usernameOwner}</dd>
          <dt>
            <span id="startDate">
              <Translate contentKey="rentcargwApp.rentcarTripCar.startDate">Start Date</Translate>
            </span>
          </dt>
          <dd>{tripCarEntity.startDate ? <TextFormat value={tripCarEntity.startDate} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="endDate">
              <Translate contentKey="rentcargwApp.rentcarTripCar.endDate">End Date</Translate>
            </span>
          </dt>
          <dd>{tripCarEntity.endDate ? <TextFormat value={tripCarEntity.endDate} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="dateDriverDeparture">
              <Translate contentKey="rentcargwApp.rentcarTripCar.dateDriverDeparture">Date Driver Departure</Translate>
            </span>
          </dt>
          <dd>
            {tripCarEntity.dateDriverDeparture ? (
              <TextFormat value={tripCarEntity.dateDriverDeparture} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="dateDriverReturn">
              <Translate contentKey="rentcargwApp.rentcarTripCar.dateDriverReturn">Date Driver Return</Translate>
            </span>
          </dt>
          <dd>
            {tripCarEntity.dateDriverReturn ? (
              <TextFormat value={tripCarEntity.dateDriverReturn} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="diadiemNhanxe">
              <Translate contentKey="rentcargwApp.rentcarTripCar.diadiemNhanxe">Diadiem Nhanxe</Translate>
            </span>
          </dt>
          <dd>{tripCarEntity.diadiemNhanxe}</dd>
          <dt>
            <span id="limitKm">
              <Translate contentKey="rentcargwApp.rentcarTripCar.limitKm">Limit Km</Translate>
            </span>
          </dt>
          <dd>{tripCarEntity.limitKm}</dd>
          <dt>
            <span id="noteRenter">
              <Translate contentKey="rentcargwApp.rentcarTripCar.noteRenter">Note Renter</Translate>
            </span>
          </dt>
          <dd>{tripCarEntity.noteRenter}</dd>
          <dt>
            <span id="feePenaltyKm">
              <Translate contentKey="rentcargwApp.rentcarTripCar.feePenaltyKm">Fee Penalty Km</Translate>
            </span>
          </dt>
          <dd>{tripCarEntity.feePenaltyKm}</dd>
          <dt>
            <span id="price">
              <Translate contentKey="rentcargwApp.rentcarTripCar.price">Price</Translate>
            </span>
          </dt>
          <dd>{tripCarEntity.price}</dd>
          <dt>
            <span id="feeSerivceId">
              <Translate contentKey="rentcargwApp.rentcarTripCar.feeSerivceId">Fee Serivce Id</Translate>
            </span>
          </dt>
          <dd>{tripCarEntity.feeSerivceId}</dd>
          <dt>
            <span id="amtFeeService">
              <Translate contentKey="rentcargwApp.rentcarTripCar.amtFeeService">Amt Fee Service</Translate>
            </span>
          </dt>
          <dd>{tripCarEntity.amtFeeService}</dd>
          <dt>
            <span id="amtFeePercent">
              <Translate contentKey="rentcargwApp.rentcarTripCar.amtFeePercent">Amt Fee Percent</Translate>
            </span>
          </dt>
          <dd>{tripCarEntity.amtFeePercent}</dd>
          <dt>
            <span id="feeInsuranceId">
              <Translate contentKey="rentcargwApp.rentcarTripCar.feeInsuranceId">Fee Insurance Id</Translate>
            </span>
          </dt>
          <dd>{tripCarEntity.feeInsuranceId}</dd>
          <dt>
            <span id="amtInsurance">
              <Translate contentKey="rentcargwApp.rentcarTripCar.amtInsurance">Amt Insurance</Translate>
            </span>
          </dt>
          <dd>{tripCarEntity.amtInsurance}</dd>
          <dt>
            <span id="amtInsurancePercent">
              <Translate contentKey="rentcargwApp.rentcarTripCar.amtInsurancePercent">Amt Insurance Percent</Translate>
            </span>
          </dt>
          <dd>{tripCarEntity.amtInsurancePercent}</dd>
          <dt>
            <span id="promotionId">
              <Translate contentKey="rentcargwApp.rentcarTripCar.promotionId">Promotion Id</Translate>
            </span>
          </dt>
          <dd>{tripCarEntity.promotionId}</dd>
          <dt>
            <span id="promotionCode">
              <Translate contentKey="rentcargwApp.rentcarTripCar.promotionCode">Promotion Code</Translate>
            </span>
          </dt>
          <dd>{tripCarEntity.promotionCode}</dd>
          <dt>
            <span id="amtPromotion">
              <Translate contentKey="rentcargwApp.rentcarTripCar.amtPromotion">Amt Promotion</Translate>
            </span>
          </dt>
          <dd>{tripCarEntity.amtPromotion}</dd>
          <dt>
            <span id="amtDeposit">
              <Translate contentKey="rentcargwApp.rentcarTripCar.amtDeposit">Amt Deposit</Translate>
            </span>
          </dt>
          <dd>{tripCarEntity.amtDeposit}</dd>
          <dt>
            <span id="amtDepositPercent">
              <Translate contentKey="rentcargwApp.rentcarTripCar.amtDepositPercent">Amt Deposit Percent</Translate>
            </span>
          </dt>
          <dd>{tripCarEntity.amtDepositPercent}</dd>
          <dt>
            <span id="pricePenaltyId">
              <Translate contentKey="rentcargwApp.rentcarTripCar.pricePenaltyId">Price Penalty Id</Translate>
            </span>
          </dt>
          <dd>{tripCarEntity.pricePenaltyId}</dd>
          <dt>
            <span id="amtFeePenaltyService">
              <Translate contentKey="rentcargwApp.rentcarTripCar.amtFeePenaltyService">Amt Fee Penalty Service</Translate>
            </span>
          </dt>
          <dd>{tripCarEntity.amtFeePenaltyService}</dd>
          <dt>
            <span id="amtFeePenaltyPercent">
              <Translate contentKey="rentcargwApp.rentcarTripCar.amtFeePenaltyPercent">Amt Fee Penalty Percent</Translate>
            </span>
          </dt>
          <dd>{tripCarEntity.amtFeePenaltyPercent}</dd>
          <dt>
            <span id="totalAmt">
              <Translate contentKey="rentcargwApp.rentcarTripCar.totalAmt">Total Amt</Translate>
            </span>
          </dt>
          <dd>{tripCarEntity.totalAmt}</dd>
          <dt>
            <span id="codeCancel">
              <Translate contentKey="rentcargwApp.rentcarTripCar.codeCancel">Code Cancel</Translate>
            </span>
          </dt>
          <dd>{tripCarEntity.codeCancel}</dd>
          <dt>
            <span id="noteCancel">
              <Translate contentKey="rentcargwApp.rentcarTripCar.noteCancel">Note Cancel</Translate>
            </span>
          </dt>
          <dd>{tripCarEntity.noteCancel}</dd>
          <dt>
            <span id="statusTrip">
              <Translate contentKey="rentcargwApp.rentcarTripCar.statusTrip">Status Trip</Translate>
            </span>
          </dt>
          <dd>{tripCarEntity.statusTrip}</dd>
          <dt>
            <span id="createDate">
              <Translate contentKey="rentcargwApp.rentcarTripCar.createDate">Create Date</Translate>
            </span>
          </dt>
          <dd>{tripCarEntity.createDate ? <TextFormat value={tripCarEntity.createDate} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="createBy">
              <Translate contentKey="rentcargwApp.rentcarTripCar.createBy">Create By</Translate>
            </span>
          </dt>
          <dd>{tripCarEntity.createBy}</dd>
          <dt>
            <span id="modifyDate">
              <Translate contentKey="rentcargwApp.rentcarTripCar.modifyDate">Modify Date</Translate>
            </span>
          </dt>
          <dd>{tripCarEntity.modifyDate ? <TextFormat value={tripCarEntity.modifyDate} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="modifyBy">
              <Translate contentKey="rentcargwApp.rentcarTripCar.modifyBy">Modify By</Translate>
            </span>
          </dt>
          <dd>{tripCarEntity.modifyBy}</dd>
          <dt>
            <Translate contentKey="rentcargwApp.rentcarTripCar.ownercar">Ownercar</Translate>
          </dt>
          <dd>{tripCarEntity.ownercar ? tripCarEntity.ownercar.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/trip-car" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/trip-car/${tripCarEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default TripCarDetail;
