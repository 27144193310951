import React from 'react';
import { Translate } from 'react-jhipster';

import MenuItem from 'app/shared/layout/menus/menu-item';

const EntitiesMenu = () => {
  return (
    <>
      {/* prettier-ignore */}
      <MenuItem icon="asterisk" to="/d-manufacter">
        <Translate contentKey="global.menu.entities.rentcarDManufacter" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/d-model">
        <Translate contentKey="global.menu.entities.rentcarDModel" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/d-policy">
        <Translate contentKey="global.menu.entities.rentcarDPolicy" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/d-price-penalty">
        <Translate contentKey="global.menu.entities.rentcarDPricePenalty" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/owner-car">
        <Translate contentKey="global.menu.entities.rentcarOwnerCar" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/price-deposit-type">
        <Translate contentKey="global.menu.entities.rentcarPriceDepositType" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/trip-car">
        <Translate contentKey="global.menu.entities.rentcarTripCar" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/voted">
        <Translate contentKey="global.menu.entities.rentcarVoted" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/payment-trip">
        <Translate contentKey="global.menu.entities.rentcarPaymentTrip" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/user-favourite">
        <Translate contentKey="global.menu.entities.rentcarUserFavourite" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/notification-key-user">
        <Translate contentKey="global.menu.entities.rentcarNotificationKeyUser" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/refferal-user-for-renter">
        <Translate contentKey="global.menu.entities.rentcarRefferalUserForRenter" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/d-promotion">
        <Translate contentKey="global.menu.entities.rentcarDPromotion" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/feature-extra">
        <Translate contentKey="global.menu.entities.rentcarFeatureExtra" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/notification-type">
        <Translate contentKey="global.menu.entities.rentcarNotificationType" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/photo-before-trip">
        <Translate contentKey="global.menu.entities.rentcarPhotoBeforeTrip" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/push-noti-history">
        <Translate contentKey="global.menu.entities.rentcarPushNotiHistory" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/fee-extra">
        <Translate contentKey="global.menu.entities.rentcarFeeExtra" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/notification-user">
        <Translate contentKey="global.menu.entities.rentcarNotificationUser" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/trip-fee-extra">
        <Translate contentKey="global.menu.entities.rentcarTripFeeExtra" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/trip-car-history">
        <Translate contentKey="global.menu.entities.rentcarTripCarHistory" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/price-rent">
        <Translate contentKey="global.menu.entities.rentcarPriceRent" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/trip-feature-extra">
        <Translate contentKey="global.menu.entities.rentcarTripFeatureExtra" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/d-fee-insurance">
        <Translate contentKey="global.menu.entities.rentcarDFeeInsurance" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/photo">
        <Translate contentKey="global.menu.entities.rentcarPhoto" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/d-fee-service">
        <Translate contentKey="global.menu.entities.rentcarDFeeService" />
      </MenuItem>
      {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
    </>
  );
};

export default EntitiesMenu;
