import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { INotificationType } from 'app/shared/model/rentcar/notification-type.model';
import { getEntities as getNotificationTypes } from 'app/entities/rentcar/notification-type/notification-type.reducer';
import { INotificationUser } from 'app/shared/model/rentcar/notification-user.model';
import { getEntity, updateEntity, createEntity, reset } from './notification-user.reducer';

export const NotificationUserUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const notificationTypes = useAppSelector(state => state.rentcargw.notificationType.entities);
  const notificationUserEntity = useAppSelector(state => state.rentcargw.notificationUser.entity);
  const loading = useAppSelector(state => state.rentcargw.notificationUser.loading);
  const updating = useAppSelector(state => state.rentcargw.notificationUser.updating);
  const updateSuccess = useAppSelector(state => state.rentcargw.notificationUser.updateSuccess);

  const handleClose = () => {
    navigate('/notification-user');
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getNotificationTypes({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.createDate = convertDateTimeToServer(values.createDate);
    values.modifyDate = convertDateTimeToServer(values.modifyDate);

    const entity = {
      ...notificationUserEntity,
      ...values,
      notitype: notificationTypes.find(it => it.id.toString() === values.notitype.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          createDate: displayDefaultDateTime(),
          modifyDate: displayDefaultDateTime(),
        }
      : {
          ...notificationUserEntity,
          createDate: convertDateTimeFromServer(notificationUserEntity.createDate),
          modifyDate: convertDateTimeFromServer(notificationUserEntity.modifyDate),
          notitype: notificationUserEntity?.notitype?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="rentcargwApp.rentcarNotificationUser.home.createOrEditLabel" data-cy="NotificationUserCreateUpdateHeading">
            <Translate contentKey="rentcargwApp.rentcarNotificationUser.home.createOrEditLabel">
              Create or edit a NotificationUser
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="notification-user-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('rentcargwApp.rentcarNotificationUser.username')}
                id="notification-user-username"
                name="username"
                data-cy="username"
                type="text"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarNotificationUser.status')}
                id="notification-user-status"
                name="status"
                data-cy="status"
                type="text"
                validate={{
                  maxLength: { value: 1, message: translate('entity.validation.maxlength', { max: 1 }) },
                }}
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarNotificationUser.title')}
                id="notification-user-title"
                name="title"
                data-cy="title"
                type="text"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarNotificationUser.description')}
                id="notification-user-description"
                name="description"
                data-cy="description"
                type="text"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarNotificationUser.shortDescription')}
                id="notification-user-shortDescription"
                name="shortDescription"
                data-cy="shortDescription"
                type="text"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarNotificationUser.linkImage')}
                id="notification-user-linkImage"
                name="linkImage"
                data-cy="linkImage"
                type="text"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarNotificationUser.isActive')}
                id="notification-user-isActive"
                name="isActive"
                data-cy="isActive"
                type="text"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarNotificationUser.createDate')}
                id="notification-user-createDate"
                name="createDate"
                data-cy="createDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarNotificationUser.createBy')}
                id="notification-user-createBy"
                name="createBy"
                data-cy="createBy"
                type="text"
                validate={{
                  maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                }}
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarNotificationUser.modifyDate')}
                id="notification-user-modifyDate"
                name="modifyDate"
                data-cy="modifyDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarNotificationUser.modifyBy')}
                id="notification-user-modifyBy"
                name="modifyBy"
                data-cy="modifyBy"
                type="text"
                validate={{
                  maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                }}
              />
              <ValidatedField
                id="notification-user-notitype"
                name="notitype"
                data-cy="notitype"
                label={translate('rentcargwApp.rentcarNotificationUser.notitype')}
                type="select"
              >
                <option value="" key="0" />
                {notificationTypes
                  ? notificationTypes.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/notification-user" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default NotificationUserUpdate;
