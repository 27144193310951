import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import PaymentTrip from './payment-trip';
import PaymentTripDetail from './payment-trip-detail';
import PaymentTripUpdate from './payment-trip-update';
import PaymentTripDeleteDialog from './payment-trip-delete-dialog';

const PaymentTripRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<PaymentTrip />} />
    <Route path="new" element={<PaymentTripUpdate />} />
    <Route path=":id">
      <Route index element={<PaymentTripDetail />} />
      <Route path="edit" element={<PaymentTripUpdate />} />
      <Route path="delete" element={<PaymentTripDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default PaymentTripRoutes;
