import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IOwnerCar } from 'app/shared/model/rentcar/owner-car.model';
import { getEntities as getOwnerCars } from 'app/entities/rentcar/owner-car/owner-car.reducer';
import { IUserFavourite } from 'app/shared/model/rentcar/user-favourite.model';
import { getEntity, updateEntity, createEntity, reset } from './user-favourite.reducer';

export const UserFavouriteUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const ownerCars = useAppSelector(state => state.rentcargw.ownerCar.entities);
  const userFavouriteEntity = useAppSelector(state => state.rentcargw.userFavourite.entity);
  const loading = useAppSelector(state => state.rentcargw.userFavourite.loading);
  const updating = useAppSelector(state => state.rentcargw.userFavourite.updating);
  const updateSuccess = useAppSelector(state => state.rentcargw.userFavourite.updateSuccess);

  const handleClose = () => {
    navigate('/user-favourite' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getOwnerCars({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.createDate = convertDateTimeToServer(values.createDate);
    values.modifyDate = convertDateTimeToServer(values.modifyDate);

    const entity = {
      ...userFavouriteEntity,
      ...values,
      owner: ownerCars.find(it => it.id.toString() === values.owner.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          createDate: displayDefaultDateTime(),
          modifyDate: displayDefaultDateTime(),
        }
      : {
          ...userFavouriteEntity,
          createDate: convertDateTimeFromServer(userFavouriteEntity.createDate),
          modifyDate: convertDateTimeFromServer(userFavouriteEntity.modifyDate),
          owner: userFavouriteEntity?.owner?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="rentcargwApp.rentcarUserFavourite.home.createOrEditLabel" data-cy="UserFavouriteCreateUpdateHeading">
            <Translate contentKey="rentcargwApp.rentcarUserFavourite.home.createOrEditLabel">Create or edit a UserFavourite</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="user-favourite-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('rentcargwApp.rentcarUserFavourite.numberPlate')}
                id="user-favourite-numberPlate"
                name="numberPlate"
                data-cy="numberPlate"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 20, message: translate('entity.validation.maxlength', { max: 20 }) },
                }}
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarUserFavourite.usernameRenter')}
                id="user-favourite-usernameRenter"
                name="usernameRenter"
                data-cy="usernameRenter"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                }}
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarUserFavourite.createDate')}
                id="user-favourite-createDate"
                name="createDate"
                data-cy="createDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarUserFavourite.createBy')}
                id="user-favourite-createBy"
                name="createBy"
                data-cy="createBy"
                type="text"
                validate={{
                  maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                }}
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarUserFavourite.modifyDate')}
                id="user-favourite-modifyDate"
                name="modifyDate"
                data-cy="modifyDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarUserFavourite.modifyBy')}
                id="user-favourite-modifyBy"
                name="modifyBy"
                data-cy="modifyBy"
                type="text"
                validate={{
                  maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                }}
              />
              <ValidatedField
                id="user-favourite-owner"
                name="owner"
                data-cy="owner"
                label={translate('rentcargwApp.rentcarUserFavourite.owner')}
                type="select"
              >
                <option value="" key="0" />
                {ownerCars
                  ? ownerCars.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/user-favourite" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default UserFavouriteUpdate;
