import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import TripCarHistory from './trip-car-history';
import TripCarHistoryDetail from './trip-car-history-detail';
import TripCarHistoryUpdate from './trip-car-history-update';
import TripCarHistoryDeleteDialog from './trip-car-history-delete-dialog';

const TripCarHistoryRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<TripCarHistory />} />
    <Route path="new" element={<TripCarHistoryUpdate />} />
    <Route path=":id">
      <Route index element={<TripCarHistoryDetail />} />
      <Route path="edit" element={<TripCarHistoryUpdate />} />
      <Route path="delete" element={<TripCarHistoryDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default TripCarHistoryRoutes;
