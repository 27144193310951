import dayjs from 'dayjs';
import { IOwnerCar } from 'app/shared/model/rentcar/owner-car.model';

export interface IPriceRent {
  id?: number;
  numberPlate?: string;
  price?: number | null;
  isActive?: string | null;
  createDate?: string | null;
  createBy?: string | null;
  modifyDate?: string | null;
  modifyBy?: string | null;
  owner?: IOwnerCar | null;
}

export const defaultValue: Readonly<IPriceRent> = {};
