import dayjs from 'dayjs';
import { IOwnerCar } from 'app/shared/model/rentcar/owner-car.model';

export interface IFeatureExtra {
  id?: number;
  codeExtra?: string | null;
  description?: string | null;
  data?: string | null;
  createDate?: string | null;
  createBy?: string | null;
  modifyDate?: string | null;
  modifyBy?: string | null;
  owner?: IOwnerCar | null;
}

export const defaultValue: Readonly<IFeatureExtra> = {};
