import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './trip-car-history.reducer';

export const TripCarHistoryDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const tripCarHistoryEntity = useAppSelector(state => state.rentcargw.tripCarHistory.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="tripCarHistoryDetailsHeading">
          <Translate contentKey="rentcargwApp.rentcarTripCarHistory.detail.title">TripCarHistory</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{tripCarHistoryEntity.id}</dd>
          <dt>
            <span id="codeTrip">
              <Translate contentKey="rentcargwApp.rentcarTripCarHistory.codeTrip">Code Trip</Translate>
            </span>
          </dt>
          <dd>{tripCarHistoryEntity.codeTrip}</dd>
          <dt>
            <span id="numberPlate">
              <Translate contentKey="rentcargwApp.rentcarTripCarHistory.numberPlate">Number Plate</Translate>
            </span>
          </dt>
          <dd>{tripCarHistoryEntity.numberPlate}</dd>
          <dt>
            <span id="usernameRenter">
              <Translate contentKey="rentcargwApp.rentcarTripCarHistory.usernameRenter">Username Renter</Translate>
            </span>
          </dt>
          <dd>{tripCarHistoryEntity.usernameRenter}</dd>
          <dt>
            <span id="usernameOwner">
              <Translate contentKey="rentcargwApp.rentcarTripCarHistory.usernameOwner">Username Owner</Translate>
            </span>
          </dt>
          <dd>{tripCarHistoryEntity.usernameOwner}</dd>
          <dt>
            <span id="startDate">
              <Translate contentKey="rentcargwApp.rentcarTripCarHistory.startDate">Start Date</Translate>
            </span>
          </dt>
          <dd>
            {tripCarHistoryEntity.startDate ? (
              <TextFormat value={tripCarHistoryEntity.startDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="endDate">
              <Translate contentKey="rentcargwApp.rentcarTripCarHistory.endDate">End Date</Translate>
            </span>
          </dt>
          <dd>
            {tripCarHistoryEntity.endDate ? <TextFormat value={tripCarHistoryEntity.endDate} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="dateDriverDeparture">
              <Translate contentKey="rentcargwApp.rentcarTripCarHistory.dateDriverDeparture">Date Driver Departure</Translate>
            </span>
          </dt>
          <dd>
            {tripCarHistoryEntity.dateDriverDeparture ? (
              <TextFormat value={tripCarHistoryEntity.dateDriverDeparture} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="dateDriverReturn">
              <Translate contentKey="rentcargwApp.rentcarTripCarHistory.dateDriverReturn">Date Driver Return</Translate>
            </span>
          </dt>
          <dd>
            {tripCarHistoryEntity.dateDriverReturn ? (
              <TextFormat value={tripCarHistoryEntity.dateDriverReturn} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="diadiemNhanxe">
              <Translate contentKey="rentcargwApp.rentcarTripCarHistory.diadiemNhanxe">Diadiem Nhanxe</Translate>
            </span>
          </dt>
          <dd>{tripCarHistoryEntity.diadiemNhanxe}</dd>
          <dt>
            <span id="limitKm">
              <Translate contentKey="rentcargwApp.rentcarTripCarHistory.limitKm">Limit Km</Translate>
            </span>
          </dt>
          <dd>{tripCarHistoryEntity.limitKm}</dd>
          <dt>
            <span id="noteRenter">
              <Translate contentKey="rentcargwApp.rentcarTripCarHistory.noteRenter">Note Renter</Translate>
            </span>
          </dt>
          <dd>{tripCarHistoryEntity.noteRenter}</dd>
          <dt>
            <span id="feePenaltyKm">
              <Translate contentKey="rentcargwApp.rentcarTripCarHistory.feePenaltyKm">Fee Penalty Km</Translate>
            </span>
          </dt>
          <dd>{tripCarHistoryEntity.feePenaltyKm}</dd>
          <dt>
            <span id="price">
              <Translate contentKey="rentcargwApp.rentcarTripCarHistory.price">Price</Translate>
            </span>
          </dt>
          <dd>{tripCarHistoryEntity.price}</dd>
          <dt>
            <span id="feeSerivceId">
              <Translate contentKey="rentcargwApp.rentcarTripCarHistory.feeSerivceId">Fee Serivce Id</Translate>
            </span>
          </dt>
          <dd>{tripCarHistoryEntity.feeSerivceId}</dd>
          <dt>
            <span id="amtFeeService">
              <Translate contentKey="rentcargwApp.rentcarTripCarHistory.amtFeeService">Amt Fee Service</Translate>
            </span>
          </dt>
          <dd>{tripCarHistoryEntity.amtFeeService}</dd>
          <dt>
            <span id="amtFeePercent">
              <Translate contentKey="rentcargwApp.rentcarTripCarHistory.amtFeePercent">Amt Fee Percent</Translate>
            </span>
          </dt>
          <dd>{tripCarHistoryEntity.amtFeePercent}</dd>
          <dt>
            <span id="feeInsuranceId">
              <Translate contentKey="rentcargwApp.rentcarTripCarHistory.feeInsuranceId">Fee Insurance Id</Translate>
            </span>
          </dt>
          <dd>{tripCarHistoryEntity.feeInsuranceId}</dd>
          <dt>
            <span id="amtInsurance">
              <Translate contentKey="rentcargwApp.rentcarTripCarHistory.amtInsurance">Amt Insurance</Translate>
            </span>
          </dt>
          <dd>{tripCarHistoryEntity.amtInsurance}</dd>
          <dt>
            <span id="amtInsurancePercent">
              <Translate contentKey="rentcargwApp.rentcarTripCarHistory.amtInsurancePercent">Amt Insurance Percent</Translate>
            </span>
          </dt>
          <dd>{tripCarHistoryEntity.amtInsurancePercent}</dd>
          <dt>
            <span id="promotionId">
              <Translate contentKey="rentcargwApp.rentcarTripCarHistory.promotionId">Promotion Id</Translate>
            </span>
          </dt>
          <dd>{tripCarHistoryEntity.promotionId}</dd>
          <dt>
            <span id="promotionCode">
              <Translate contentKey="rentcargwApp.rentcarTripCarHistory.promotionCode">Promotion Code</Translate>
            </span>
          </dt>
          <dd>{tripCarHistoryEntity.promotionCode}</dd>
          <dt>
            <span id="amtPromotion">
              <Translate contentKey="rentcargwApp.rentcarTripCarHistory.amtPromotion">Amt Promotion</Translate>
            </span>
          </dt>
          <dd>{tripCarHistoryEntity.amtPromotion}</dd>
          <dt>
            <span id="amtDeposit">
              <Translate contentKey="rentcargwApp.rentcarTripCarHistory.amtDeposit">Amt Deposit</Translate>
            </span>
          </dt>
          <dd>{tripCarHistoryEntity.amtDeposit}</dd>
          <dt>
            <span id="amtDepositPercent">
              <Translate contentKey="rentcargwApp.rentcarTripCarHistory.amtDepositPercent">Amt Deposit Percent</Translate>
            </span>
          </dt>
          <dd>{tripCarHistoryEntity.amtDepositPercent}</dd>
          <dt>
            <span id="pricePenaltyId">
              <Translate contentKey="rentcargwApp.rentcarTripCarHistory.pricePenaltyId">Price Penalty Id</Translate>
            </span>
          </dt>
          <dd>{tripCarHistoryEntity.pricePenaltyId}</dd>
          <dt>
            <span id="amtFeePenaltyService">
              <Translate contentKey="rentcargwApp.rentcarTripCarHistory.amtFeePenaltyService">Amt Fee Penalty Service</Translate>
            </span>
          </dt>
          <dd>{tripCarHistoryEntity.amtFeePenaltyService}</dd>
          <dt>
            <span id="amtFeePenaltyPercent">
              <Translate contentKey="rentcargwApp.rentcarTripCarHistory.amtFeePenaltyPercent">Amt Fee Penalty Percent</Translate>
            </span>
          </dt>
          <dd>{tripCarHistoryEntity.amtFeePenaltyPercent}</dd>
          <dt>
            <span id="totalAmt">
              <Translate contentKey="rentcargwApp.rentcarTripCarHistory.totalAmt">Total Amt</Translate>
            </span>
          </dt>
          <dd>{tripCarHistoryEntity.totalAmt}</dd>
          <dt>
            <span id="codeCancel">
              <Translate contentKey="rentcargwApp.rentcarTripCarHistory.codeCancel">Code Cancel</Translate>
            </span>
          </dt>
          <dd>{tripCarHistoryEntity.codeCancel}</dd>
          <dt>
            <span id="noteCancel">
              <Translate contentKey="rentcargwApp.rentcarTripCarHistory.noteCancel">Note Cancel</Translate>
            </span>
          </dt>
          <dd>{tripCarHistoryEntity.noteCancel}</dd>
          <dt>
            <span id="statusTrip">
              <Translate contentKey="rentcargwApp.rentcarTripCarHistory.statusTrip">Status Trip</Translate>
            </span>
          </dt>
          <dd>{tripCarHistoryEntity.statusTrip}</dd>
          <dt>
            <span id="createDate">
              <Translate contentKey="rentcargwApp.rentcarTripCarHistory.createDate">Create Date</Translate>
            </span>
          </dt>
          <dd>
            {tripCarHistoryEntity.createDate ? (
              <TextFormat value={tripCarHistoryEntity.createDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="createBy">
              <Translate contentKey="rentcargwApp.rentcarTripCarHistory.createBy">Create By</Translate>
            </span>
          </dt>
          <dd>{tripCarHistoryEntity.createBy}</dd>
          <dt>
            <span id="modifyDate">
              <Translate contentKey="rentcargwApp.rentcarTripCarHistory.modifyDate">Modify Date</Translate>
            </span>
          </dt>
          <dd>
            {tripCarHistoryEntity.modifyDate ? (
              <TextFormat value={tripCarHistoryEntity.modifyDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="modifyBy">
              <Translate contentKey="rentcargwApp.rentcarTripCarHistory.modifyBy">Modify By</Translate>
            </span>
          </dt>
          <dd>{tripCarHistoryEntity.modifyBy}</dd>
        </dl>
        <Button tag={Link} to="/trip-car-history" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/trip-car-history/${tripCarHistoryEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default TripCarHistoryDetail;
