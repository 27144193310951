import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './notification-user.reducer';

export const NotificationUserDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const notificationUserEntity = useAppSelector(state => state.rentcargw.notificationUser.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="notificationUserDetailsHeading">
          <Translate contentKey="rentcargwApp.rentcarNotificationUser.detail.title">NotificationUser</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{notificationUserEntity.id}</dd>
          <dt>
            <span id="username">
              <Translate contentKey="rentcargwApp.rentcarNotificationUser.username">Username</Translate>
            </span>
          </dt>
          <dd>{notificationUserEntity.username}</dd>
          <dt>
            <span id="status">
              <Translate contentKey="rentcargwApp.rentcarNotificationUser.status">Status</Translate>
            </span>
          </dt>
          <dd>{notificationUserEntity.status}</dd>
          <dt>
            <span id="title">
              <Translate contentKey="rentcargwApp.rentcarNotificationUser.title">Title</Translate>
            </span>
          </dt>
          <dd>{notificationUserEntity.title}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="rentcargwApp.rentcarNotificationUser.description">Description</Translate>
            </span>
          </dt>
          <dd>{notificationUserEntity.description}</dd>
          <dt>
            <span id="shortDescription">
              <Translate contentKey="rentcargwApp.rentcarNotificationUser.shortDescription">Short Description</Translate>
            </span>
          </dt>
          <dd>{notificationUserEntity.shortDescription}</dd>
          <dt>
            <span id="linkImage">
              <Translate contentKey="rentcargwApp.rentcarNotificationUser.linkImage">Link Image</Translate>
            </span>
          </dt>
          <dd>{notificationUserEntity.linkImage}</dd>
          <dt>
            <span id="isActive">
              <Translate contentKey="rentcargwApp.rentcarNotificationUser.isActive">Is Active</Translate>
            </span>
          </dt>
          <dd>{notificationUserEntity.isActive}</dd>
          <dt>
            <span id="createDate">
              <Translate contentKey="rentcargwApp.rentcarNotificationUser.createDate">Create Date</Translate>
            </span>
          </dt>
          <dd>
            {notificationUserEntity.createDate ? (
              <TextFormat value={notificationUserEntity.createDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="createBy">
              <Translate contentKey="rentcargwApp.rentcarNotificationUser.createBy">Create By</Translate>
            </span>
          </dt>
          <dd>{notificationUserEntity.createBy}</dd>
          <dt>
            <span id="modifyDate">
              <Translate contentKey="rentcargwApp.rentcarNotificationUser.modifyDate">Modify Date</Translate>
            </span>
          </dt>
          <dd>
            {notificationUserEntity.modifyDate ? (
              <TextFormat value={notificationUserEntity.modifyDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="modifyBy">
              <Translate contentKey="rentcargwApp.rentcarNotificationUser.modifyBy">Modify By</Translate>
            </span>
          </dt>
          <dd>{notificationUserEntity.modifyBy}</dd>
          <dt>
            <Translate contentKey="rentcargwApp.rentcarNotificationUser.notitype">Notitype</Translate>
          </dt>
          <dd>{notificationUserEntity.notitype ? notificationUserEntity.notitype.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/notification-user" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/notification-user/${notificationUserEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default NotificationUserDetail;
