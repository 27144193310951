import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './photo.reducer';

export const PhotoDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const photoEntity = useAppSelector(state => state.rentcargw.photo.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="photoDetailsHeading">
          <Translate contentKey="rentcargwApp.rentcarPhoto.detail.title">Photo</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{photoEntity.id}</dd>
          <dt>
            <span id="numberPlate">
              <Translate contentKey="rentcargwApp.rentcarPhoto.numberPlate">Number Plate</Translate>
            </span>
          </dt>
          <dd>{photoEntity.numberPlate}</dd>
          <dt>
            <span id="fileName">
              <Translate contentKey="rentcargwApp.rentcarPhoto.fileName">File Name</Translate>
            </span>
          </dt>
          <dd>{photoEntity.fileName}</dd>
          <dt>
            <span id="pathFiles">
              <Translate contentKey="rentcargwApp.rentcarPhoto.pathFiles">Path Files</Translate>
            </span>
          </dt>
          <dd>{photoEntity.pathFiles}</dd>
          <dt>
            <span id="isShow">
              <Translate contentKey="rentcargwApp.rentcarPhoto.isShow">Is Show</Translate>
            </span>
          </dt>
          <dd>{photoEntity.isShow}</dd>
          <dt>
            <span id="orderNo">
              <Translate contentKey="rentcargwApp.rentcarPhoto.orderNo">Order No</Translate>
            </span>
          </dt>
          <dd>{photoEntity.orderNo}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="rentcargwApp.rentcarPhoto.description">Description</Translate>
            </span>
          </dt>
          <dd>{photoEntity.description}</dd>
          <dt>
            <span id="data">
              <Translate contentKey="rentcargwApp.rentcarPhoto.data">Data</Translate>
            </span>
          </dt>
          <dd>{photoEntity.data}</dd>
          <dt>
            <span id="createDate">
              <Translate contentKey="rentcargwApp.rentcarPhoto.createDate">Create Date</Translate>
            </span>
          </dt>
          <dd>{photoEntity.createDate ? <TextFormat value={photoEntity.createDate} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="createBy">
              <Translate contentKey="rentcargwApp.rentcarPhoto.createBy">Create By</Translate>
            </span>
          </dt>
          <dd>{photoEntity.createBy}</dd>
          <dt>
            <span id="modifyDate">
              <Translate contentKey="rentcargwApp.rentcarPhoto.modifyDate">Modify Date</Translate>
            </span>
          </dt>
          <dd>{photoEntity.modifyDate ? <TextFormat value={photoEntity.modifyDate} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="modifyBy">
              <Translate contentKey="rentcargwApp.rentcarPhoto.modifyBy">Modify By</Translate>
            </span>
          </dt>
          <dd>{photoEntity.modifyBy}</dd>
          <dt>
            <Translate contentKey="rentcargwApp.rentcarPhoto.owner">Owner</Translate>
          </dt>
          <dd>{photoEntity.owner ? photoEntity.owner.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/photo" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/photo/${photoEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default PhotoDetail;
