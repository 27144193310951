import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import PhotoBeforeTrip from './photo-before-trip';
import PhotoBeforeTripDetail from './photo-before-trip-detail';
import PhotoBeforeTripUpdate from './photo-before-trip-update';
import PhotoBeforeTripDeleteDialog from './photo-before-trip-delete-dialog';

const PhotoBeforeTripRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<PhotoBeforeTrip />} />
    <Route path="new" element={<PhotoBeforeTripUpdate />} />
    <Route path=":id">
      <Route index element={<PhotoBeforeTripDetail />} />
      <Route path="edit" element={<PhotoBeforeTripUpdate />} />
      <Route path="delete" element={<PhotoBeforeTripDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default PhotoBeforeTripRoutes;
