import dayjs from 'dayjs';
import { ITripCar } from 'app/shared/model/rentcar/trip-car.model';

export interface IVoted {
  id?: number;
  numberPlate?: string;
  tripCode?: string | null;
  noted?: string;
  starOfNumber?: number | null;
  usernameOwner?: string | null;
  fullNameOwner?: string | null;
  usernameRenter?: string | null;
  fullNameRenter?: string | null;
  createDate?: string | null;
  createBy?: string | null;
  modifyDate?: string | null;
  modifyBy?: string | null;
  tripcar?: ITripCar | null;
}

export const defaultValue: Readonly<IVoted> = {};
