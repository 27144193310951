import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import { ReducersMapObject, combineReducers } from '@reduxjs/toolkit';

import getStore from 'app/config/store';

import entitiesReducers from './reducers';

import DManufacter from './rentcar/d-manufacter';
import DModel from './rentcar/d-model';
import DPolicy from './rentcar/d-policy';
import DPricePenalty from './rentcar/d-price-penalty';
import OwnerCar from './rentcar/owner-car';
import PriceDepositType from './rentcar/price-deposit-type';
import TripCar from './rentcar/trip-car';
import Voted from './rentcar/voted';
import PaymentTrip from './rentcar/payment-trip';
import UserFavourite from './rentcar/user-favourite';
import NotificationKeyUser from './rentcar/notification-key-user';
import RefferalUserForRenter from './rentcar/refferal-user-for-renter';
import DPromotion from './rentcar/d-promotion';
import FeatureExtra from './rentcar/feature-extra';
import NotificationType from './rentcar/notification-type';
import PhotoBeforeTrip from './rentcar/photo-before-trip';
import PushNotiHistory from './rentcar/push-noti-history';
import FeeExtra from './rentcar/fee-extra';
import NotificationUser from './rentcar/notification-user';
import TripFeeExtra from './rentcar/trip-fee-extra';
import TripCarHistory from './rentcar/trip-car-history';
import PriceRent from './rentcar/price-rent';
import TripFeatureExtra from './rentcar/trip-feature-extra';
import DFeeInsurance from './rentcar/d-fee-insurance';
import Photo from './rentcar/photo';
import DFeeService from './rentcar/d-fee-service';
/* jhipster-needle-add-route-import - JHipster will add routes here */

export default () => {
  const store = getStore();
  store.injectReducer('rentcargw', combineReducers(entitiesReducers as ReducersMapObject));
  return (
    <div>
      <ErrorBoundaryRoutes>
        {/* prettier-ignore */}
        <Route path="d-manufacter/*" element={<DManufacter />} />
        <Route path="d-model/*" element={<DModel />} />
        <Route path="d-policy/*" element={<DPolicy />} />
        <Route path="d-price-penalty/*" element={<DPricePenalty />} />
        <Route path="owner-car/*" element={<OwnerCar />} />
        <Route path="price-deposit-type/*" element={<PriceDepositType />} />
        <Route path="trip-car/*" element={<TripCar />} />
        <Route path="voted/*" element={<Voted />} />
        <Route path="payment-trip/*" element={<PaymentTrip />} />
        <Route path="user-favourite/*" element={<UserFavourite />} />
        <Route path="notification-key-user/*" element={<NotificationKeyUser />} />
        <Route path="refferal-user-for-renter/*" element={<RefferalUserForRenter />} />
        <Route path="d-promotion/*" element={<DPromotion />} />
        <Route path="feature-extra/*" element={<FeatureExtra />} />
        <Route path="notification-type/*" element={<NotificationType />} />
        <Route path="photo-before-trip/*" element={<PhotoBeforeTrip />} />
        <Route path="push-noti-history/*" element={<PushNotiHistory />} />
        <Route path="fee-extra/*" element={<FeeExtra />} />
        <Route path="notification-user/*" element={<NotificationUser />} />
        <Route path="trip-fee-extra/*" element={<TripFeeExtra />} />
        <Route path="trip-car-history/*" element={<TripCarHistory />} />
        <Route path="price-rent/*" element={<PriceRent />} />
        <Route path="trip-feature-extra/*" element={<TripFeatureExtra />} />
        <Route path="d-fee-insurance/*" element={<DFeeInsurance />} />
        <Route path="photo/*" element={<Photo />} />
        <Route path="d-fee-service/*" element={<DFeeService />} />
        {/* jhipster-needle-add-route-path - JHipster will add routes here */}
      </ErrorBoundaryRoutes>
    </div>
  );
};
