import dayjs from 'dayjs';
import { INotificationType } from 'app/shared/model/rentcar/notification-type.model';

export interface INotificationUser {
  id?: number;
  username?: string | null;
  status?: string | null;
  title?: string | null;
  description?: string | null;
  shortDescription?: string | null;
  linkImage?: string | null;
  isActive?: string | null;
  createDate?: string | null;
  createBy?: string | null;
  modifyDate?: string | null;
  modifyBy?: string | null;
  notitype?: INotificationType | null;
}

export const defaultValue: Readonly<INotificationUser> = {};
