import dayjs from 'dayjs';
import { IOwnerCar } from 'app/shared/model/rentcar/owner-car.model';

export interface IUserFavourite {
  id?: number;
  numberPlate?: string;
  usernameRenter?: string;
  createDate?: string | null;
  createBy?: string | null;
  modifyDate?: string | null;
  modifyBy?: string | null;
  owner?: IOwnerCar | null;
}

export const defaultValue: Readonly<IUserFavourite> = {};
