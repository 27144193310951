import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import PriceRent from './price-rent';
import PriceRentDetail from './price-rent-detail';
import PriceRentUpdate from './price-rent-update';
import PriceRentDeleteDialog from './price-rent-delete-dialog';

const PriceRentRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<PriceRent />} />
    <Route path="new" element={<PriceRentUpdate />} />
    <Route path=":id">
      <Route index element={<PriceRentDetail />} />
      <Route path="edit" element={<PriceRentUpdate />} />
      <Route path="delete" element={<PriceRentDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default PriceRentRoutes;
