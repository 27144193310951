import dayjs from 'dayjs';
import { INotificationUser } from 'app/shared/model/rentcar/notification-user.model';

export interface INotificationType {
  id?: number;
  code?: string | null;
  title?: string;
  type?: string | null;
  key?: string | null;
  className?: string | null;
  methodName?: string | null;
  subjectEmail?: string | null;
  bodyEmail?: string | null;
  titleNoti?: string | null;
  bodyNoti?: string | null;
  titleNotiApp?: string | null;
  subtitleNotiApp?: string | null;
  bodyNotiApp?: string | null;
  isActive?: string | null;
  createDate?: string | null;
  createBy?: string | null;
  modifyDate?: string | null;
  modifyBy?: string | null;
  notiusers?: INotificationUser[] | null;
}

export const defaultValue: Readonly<INotificationType> = {};
