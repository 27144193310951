import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import DFeeService from './d-fee-service';
import DFeeServiceDetail from './d-fee-service-detail';
import DFeeServiceUpdate from './d-fee-service-update';
import DFeeServiceDeleteDialog from './d-fee-service-delete-dialog';

const DFeeServiceRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<DFeeService />} />
    <Route path="new" element={<DFeeServiceUpdate />} />
    <Route path=":id">
      <Route index element={<DFeeServiceDetail />} />
      <Route path="edit" element={<DFeeServiceUpdate />} />
      <Route path="delete" element={<DFeeServiceDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default DFeeServiceRoutes;
