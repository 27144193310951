import dayjs from 'dayjs';

export interface IPriceDepositType {
  id?: number;
  percentTrip?: number | null;
  createDate?: string | null;
  createBy?: string | null;
  modifyDate?: string | null;
  modifyBy?: string | null;
}

export const defaultValue: Readonly<IPriceDepositType> = {};
