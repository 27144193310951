import dayjs from 'dayjs';
import { IOwnerCar } from 'app/shared/model/rentcar/owner-car.model';

export interface IPhoto {
  id?: number;
  numberPlate?: string;
  fileName?: string | null;
  pathFiles?: string;
  isShow?: string;
  orderNo?: number | null;
  description?: string | null;
  data?: string | null;
  createDate?: string | null;
  createBy?: string | null;
  modifyDate?: string | null;
  modifyBy?: string | null;
  owner?: IOwnerCar | null;
}

export const defaultValue: Readonly<IPhoto> = {};
