import dayjs from 'dayjs';

export interface IPushNotiHistory {
  id?: number;
  userName?: string | null;
  className?: string | null;
  methodName?: string | null;
  dataRequest?: string | null;
  dataResponse?: string | null;
  status?: string | null;
  createDate?: string | null;
  createBy?: string | null;
  modifyDate?: string | null;
  modifyBy?: string | null;
}

export const defaultValue: Readonly<IPushNotiHistory> = {};
