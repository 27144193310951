import dayjs from 'dayjs';
import { IVoted } from 'app/shared/model/rentcar/voted.model';
import { IPaymentTrip } from 'app/shared/model/rentcar/payment-trip.model';
import { IPhotoBeforeTrip } from 'app/shared/model/rentcar/photo-before-trip.model';
import { ITripFeeExtra } from 'app/shared/model/rentcar/trip-fee-extra.model';
import { ITripFeatureExtra } from 'app/shared/model/rentcar/trip-feature-extra.model';
import { IOwnerCar } from 'app/shared/model/rentcar/owner-car.model';
import { EnumStatusTrip } from 'app/shared/model/enumerations/enum-status-trip.model';

export interface ITripCar {
  id?: number;
  codeTrip?: string | null;
  numberPlate?: string;
  usernameRenter?: string | null;
  usernameOwner?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  dateDriverDeparture?: string | null;
  dateDriverReturn?: string | null;
  diadiemNhanxe?: string | null;
  limitKm?: number | null;
  noteRenter?: string | null;
  feePenaltyKm?: number | null;
  price?: number | null;
  feeSerivceId?: number | null;
  amtFeeService?: number | null;
  amtFeePercent?: number | null;
  feeInsuranceId?: number | null;
  amtInsurance?: number | null;
  amtInsurancePercent?: number | null;
  promotionId?: number | null;
  promotionCode?: string | null;
  amtPromotion?: number | null;
  amtDeposit?: number | null;
  amtDepositPercent?: number | null;
  pricePenaltyId?: number | null;
  amtFeePenaltyService?: number | null;
  amtFeePenaltyPercent?: number | null;
  totalAmt?: number | null;
  codeCancel?: string | null;
  noteCancel?: string | null;
  statusTrip?: EnumStatusTrip | null;
  createDate?: string | null;
  createBy?: string | null;
  modifyDate?: string | null;
  modifyBy?: string | null;
  voteds?: IVoted[] | null;
  paytrips?: IPaymentTrip[] | null;
  photobeforetrips?: IPhotoBeforeTrip[] | null;
  tripfeeextras?: ITripFeeExtra[] | null;
  tripfeatureextras?: ITripFeatureExtra[] | null;
  ownercar?: IOwnerCar | null;
}

export const defaultValue: Readonly<ITripCar> = {};
