import { IDModel } from 'app/shared/model/rentcar/d-model.model';

export interface IDManufacter {
  id?: number;
  name?: string;
  isActive?: string;
  seqNo?: number | null;
  dmodels?: IDModel[] | null;
}

export const defaultValue: Readonly<IDManufacter> = {};
