import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { ITripCar } from 'app/shared/model/rentcar/trip-car.model';
import { getEntities as getTripCars } from 'app/entities/rentcar/trip-car/trip-car.reducer';
import { ITripFeatureExtra } from 'app/shared/model/rentcar/trip-feature-extra.model';
import { getEntity, updateEntity, createEntity, reset } from './trip-feature-extra.reducer';

export const TripFeatureExtraUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const tripCars = useAppSelector(state => state.rentcargw.tripCar.entities);
  const tripFeatureExtraEntity = useAppSelector(state => state.rentcargw.tripFeatureExtra.entity);
  const loading = useAppSelector(state => state.rentcargw.tripFeatureExtra.loading);
  const updating = useAppSelector(state => state.rentcargw.tripFeatureExtra.updating);
  const updateSuccess = useAppSelector(state => state.rentcargw.tripFeatureExtra.updateSuccess);

  const handleClose = () => {
    navigate('/trip-feature-extra');
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getTripCars({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.createDate = convertDateTimeToServer(values.createDate);
    values.modifyDate = convertDateTimeToServer(values.modifyDate);

    const entity = {
      ...tripFeatureExtraEntity,
      ...values,
      tripcar: tripCars.find(it => it.id.toString() === values.tripcar.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          createDate: displayDefaultDateTime(),
          modifyDate: displayDefaultDateTime(),
        }
      : {
          ...tripFeatureExtraEntity,
          createDate: convertDateTimeFromServer(tripFeatureExtraEntity.createDate),
          modifyDate: convertDateTimeFromServer(tripFeatureExtraEntity.modifyDate),
          tripcar: tripFeatureExtraEntity?.tripcar?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="rentcargwApp.rentcarTripFeatureExtra.home.createOrEditLabel" data-cy="TripFeatureExtraCreateUpdateHeading">
            <Translate contentKey="rentcargwApp.rentcarTripFeatureExtra.home.createOrEditLabel">
              Create or edit a TripFeatureExtra
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="trip-feature-extra-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripFeatureExtra.codeExtra')}
                id="trip-feature-extra-codeExtra"
                name="codeExtra"
                data-cy="codeExtra"
                type="text"
                validate={{
                  maxLength: { value: 20, message: translate('entity.validation.maxlength', { max: 20 }) },
                }}
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripFeatureExtra.description')}
                id="trip-feature-extra-description"
                name="description"
                data-cy="description"
                type="text"
                validate={{
                  maxLength: { value: 500, message: translate('entity.validation.maxlength', { max: 500 }) },
                }}
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripFeatureExtra.data')}
                id="trip-feature-extra-data"
                name="data"
                data-cy="data"
                type="text"
                validate={{
                  maxLength: { value: 20, message: translate('entity.validation.maxlength', { max: 20 }) },
                }}
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripFeatureExtra.createDate')}
                id="trip-feature-extra-createDate"
                name="createDate"
                data-cy="createDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripFeatureExtra.createBy')}
                id="trip-feature-extra-createBy"
                name="createBy"
                data-cy="createBy"
                type="text"
                validate={{
                  maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                }}
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripFeatureExtra.modifyDate')}
                id="trip-feature-extra-modifyDate"
                name="modifyDate"
                data-cy="modifyDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripFeatureExtra.modifyBy')}
                id="trip-feature-extra-modifyBy"
                name="modifyBy"
                data-cy="modifyBy"
                type="text"
                validate={{
                  maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                }}
              />
              <ValidatedField
                id="trip-feature-extra-tripcar"
                name="tripcar"
                data-cy="tripcar"
                label={translate('rentcargwApp.rentcarTripFeatureExtra.tripcar')}
                type="select"
              >
                <option value="" key="0" />
                {tripCars
                  ? tripCars.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/trip-feature-extra" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default TripFeatureExtraUpdate;
