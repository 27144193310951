import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import UserFavourite from './user-favourite';
import UserFavouriteDetail from './user-favourite-detail';
import UserFavouriteUpdate from './user-favourite-update';
import UserFavouriteDeleteDialog from './user-favourite-delete-dialog';

const UserFavouriteRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<UserFavourite />} />
    <Route path="new" element={<UserFavouriteUpdate />} />
    <Route path=":id">
      <Route index element={<UserFavouriteDetail />} />
      <Route path="edit" element={<UserFavouriteUpdate />} />
      <Route path="delete" element={<UserFavouriteDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default UserFavouriteRoutes;
