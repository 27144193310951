import dayjs from 'dayjs';
import { EnumStatusTrip } from 'app/shared/model/enumerations/enum-status-trip.model';

export interface ITripCarHistory {
  id?: number;
  codeTrip?: string | null;
  numberPlate?: string;
  usernameRenter?: string | null;
  usernameOwner?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  dateDriverDeparture?: string | null;
  dateDriverReturn?: string | null;
  diadiemNhanxe?: string | null;
  limitKm?: number | null;
  noteRenter?: string | null;
  feePenaltyKm?: number | null;
  price?: number | null;
  feeSerivceId?: number | null;
  amtFeeService?: number | null;
  amtFeePercent?: number | null;
  feeInsuranceId?: number | null;
  amtInsurance?: number | null;
  amtInsurancePercent?: number | null;
  promotionId?: number | null;
  promotionCode?: string | null;
  amtPromotion?: number | null;
  amtDeposit?: number | null;
  amtDepositPercent?: number | null;
  pricePenaltyId?: number | null;
  amtFeePenaltyService?: number | null;
  amtFeePenaltyPercent?: number | null;
  totalAmt?: number | null;
  codeCancel?: string | null;
  noteCancel?: string | null;
  statusTrip?: EnumStatusTrip | null;
  createDate?: string | null;
  createBy?: string | null;
  modifyDate?: string | null;
  modifyBy?: string | null;
}

export const defaultValue: Readonly<ITripCarHistory> = {};
