import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import DPromotion from './d-promotion';
import DPromotionDetail from './d-promotion-detail';
import DPromotionUpdate from './d-promotion-update';
import DPromotionDeleteDialog from './d-promotion-delete-dialog';

const DPromotionRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<DPromotion />} />
    <Route path="new" element={<DPromotionUpdate />} />
    <Route path=":id">
      <Route index element={<DPromotionDetail />} />
      <Route path="edit" element={<DPromotionUpdate />} />
      <Route path="delete" element={<DPromotionDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default DPromotionRoutes;
