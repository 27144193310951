import dManufacter from 'app/entities/rentcar/d-manufacter/d-manufacter.reducer';
import dModel from 'app/entities/rentcar/d-model/d-model.reducer';
import dPolicy from 'app/entities/rentcar/d-policy/d-policy.reducer';
import dPricePenalty from 'app/entities/rentcar/d-price-penalty/d-price-penalty.reducer';
import ownerCar from 'app/entities/rentcar/owner-car/owner-car.reducer';
import priceDepositType from 'app/entities/rentcar/price-deposit-type/price-deposit-type.reducer';
import tripCar from 'app/entities/rentcar/trip-car/trip-car.reducer';
import voted from 'app/entities/rentcar/voted/voted.reducer';
import paymentTrip from 'app/entities/rentcar/payment-trip/payment-trip.reducer';
import userFavourite from 'app/entities/rentcar/user-favourite/user-favourite.reducer';
import notificationKeyUser from 'app/entities/rentcar/notification-key-user/notification-key-user.reducer';
import refferalUserForRenter from 'app/entities/rentcar/refferal-user-for-renter/refferal-user-for-renter.reducer';
import dPromotion from 'app/entities/rentcar/d-promotion/d-promotion.reducer';
import featureExtra from 'app/entities/rentcar/feature-extra/feature-extra.reducer';
import notificationType from 'app/entities/rentcar/notification-type/notification-type.reducer';
import photoBeforeTrip from 'app/entities/rentcar/photo-before-trip/photo-before-trip.reducer';
import pushNotiHistory from 'app/entities/rentcar/push-noti-history/push-noti-history.reducer';
import feeExtra from 'app/entities/rentcar/fee-extra/fee-extra.reducer';
import notificationUser from 'app/entities/rentcar/notification-user/notification-user.reducer';
import tripFeeExtra from 'app/entities/rentcar/trip-fee-extra/trip-fee-extra.reducer';
import tripCarHistory from 'app/entities/rentcar/trip-car-history/trip-car-history.reducer';
import priceRent from 'app/entities/rentcar/price-rent/price-rent.reducer';
import tripFeatureExtra from 'app/entities/rentcar/trip-feature-extra/trip-feature-extra.reducer';
import dFeeInsurance from 'app/entities/rentcar/d-fee-insurance/d-fee-insurance.reducer';
import photo from 'app/entities/rentcar/photo/photo.reducer';
import dFeeService from 'app/entities/rentcar/d-fee-service/d-fee-service.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  dManufacter,
  dModel,
  dPolicy,
  dPricePenalty,
  ownerCar,
  priceDepositType,
  tripCar,
  voted,
  paymentTrip,
  userFavourite,
  notificationKeyUser,
  refferalUserForRenter,
  dPromotion,
  featureExtra,
  notificationType,
  photoBeforeTrip,
  pushNotiHistory,
  feeExtra,
  notificationUser,
  tripFeeExtra,
  tripCarHistory,
  priceRent,
  tripFeatureExtra,
  dFeeInsurance,
  photo,
  dFeeService,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
