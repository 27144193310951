import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './voted.reducer';

export const VotedDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const votedEntity = useAppSelector(state => state.rentcargw.voted.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="votedDetailsHeading">
          <Translate contentKey="rentcargwApp.rentcarVoted.detail.title">Voted</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{votedEntity.id}</dd>
          <dt>
            <span id="numberPlate">
              <Translate contentKey="rentcargwApp.rentcarVoted.numberPlate">Number Plate</Translate>
            </span>
          </dt>
          <dd>{votedEntity.numberPlate}</dd>
          <dt>
            <span id="tripCode">
              <Translate contentKey="rentcargwApp.rentcarVoted.tripCode">Trip Code</Translate>
            </span>
          </dt>
          <dd>{votedEntity.tripCode}</dd>
          <dt>
            <span id="noted">
              <Translate contentKey="rentcargwApp.rentcarVoted.noted">Noted</Translate>
            </span>
          </dt>
          <dd>{votedEntity.noted}</dd>
          <dt>
            <span id="starOfNumber">
              <Translate contentKey="rentcargwApp.rentcarVoted.starOfNumber">Star Of Number</Translate>
            </span>
          </dt>
          <dd>{votedEntity.starOfNumber}</dd>
          <dt>
            <span id="usernameOwner">
              <Translate contentKey="rentcargwApp.rentcarVoted.usernameOwner">Username Owner</Translate>
            </span>
          </dt>
          <dd>{votedEntity.usernameOwner}</dd>
          <dt>
            <span id="fullNameOwner">
              <Translate contentKey="rentcargwApp.rentcarVoted.fullNameOwner">Full Name Owner</Translate>
            </span>
          </dt>
          <dd>{votedEntity.fullNameOwner}</dd>
          <dt>
            <span id="usernameRenter">
              <Translate contentKey="rentcargwApp.rentcarVoted.usernameRenter">Username Renter</Translate>
            </span>
          </dt>
          <dd>{votedEntity.usernameRenter}</dd>
          <dt>
            <span id="fullNameRenter">
              <Translate contentKey="rentcargwApp.rentcarVoted.fullNameRenter">Full Name Renter</Translate>
            </span>
          </dt>
          <dd>{votedEntity.fullNameRenter}</dd>
          <dt>
            <span id="createDate">
              <Translate contentKey="rentcargwApp.rentcarVoted.createDate">Create Date</Translate>
            </span>
          </dt>
          <dd>{votedEntity.createDate ? <TextFormat value={votedEntity.createDate} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="createBy">
              <Translate contentKey="rentcargwApp.rentcarVoted.createBy">Create By</Translate>
            </span>
          </dt>
          <dd>{votedEntity.createBy}</dd>
          <dt>
            <span id="modifyDate">
              <Translate contentKey="rentcargwApp.rentcarVoted.modifyDate">Modify Date</Translate>
            </span>
          </dt>
          <dd>{votedEntity.modifyDate ? <TextFormat value={votedEntity.modifyDate} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="modifyBy">
              <Translate contentKey="rentcargwApp.rentcarVoted.modifyBy">Modify By</Translate>
            </span>
          </dt>
          <dd>{votedEntity.modifyBy}</dd>
          <dt>
            <Translate contentKey="rentcargwApp.rentcarVoted.tripcar">Tripcar</Translate>
          </dt>
          <dd>{votedEntity.tripcar ? votedEntity.tripcar.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/voted" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/voted/${votedEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default VotedDetail;
