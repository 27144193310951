import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { ITripCar } from 'app/shared/model/rentcar/trip-car.model';
import { getEntities as getTripCars } from 'app/entities/rentcar/trip-car/trip-car.reducer';
import { IPaymentTrip } from 'app/shared/model/rentcar/payment-trip.model';
import { EnumPaymentMethod } from 'app/shared/model/enumerations/enum-payment-method.model';
import { getEntity, updateEntity, createEntity, reset } from './payment-trip.reducer';

export const PaymentTripUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const tripCars = useAppSelector(state => state.rentcargw.tripCar.entities);
  const paymentTripEntity = useAppSelector(state => state.rentcargw.paymentTrip.entity);
  const loading = useAppSelector(state => state.rentcargw.paymentTrip.loading);
  const updating = useAppSelector(state => state.rentcargw.paymentTrip.updating);
  const updateSuccess = useAppSelector(state => state.rentcargw.paymentTrip.updateSuccess);
  const enumPaymentMethodValues = Object.keys(EnumPaymentMethod);

  const handleClose = () => {
    navigate('/payment-trip' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getTripCars({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...paymentTripEntity,
      ...values,
      tripcar: tripCars.find(it => it.id.toString() === values.tripcar.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          enumPaymentMethod: 'CASH',
          ...paymentTripEntity,
          tripcar: paymentTripEntity?.tripcar?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="rentcargwApp.rentcarPaymentTrip.home.createOrEditLabel" data-cy="PaymentTripCreateUpdateHeading">
            <Translate contentKey="rentcargwApp.rentcarPaymentTrip.home.createOrEditLabel">Create or edit a PaymentTrip</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="payment-trip-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('rentcargwApp.rentcarPaymentTrip.numberPlate')}
                id="payment-trip-numberPlate"
                name="numberPlate"
                data-cy="numberPlate"
                type="text"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarPaymentTrip.amount')}
                id="payment-trip-amount"
                name="amount"
                data-cy="amount"
                type="text"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarPaymentTrip.enumPaymentMethod')}
                id="payment-trip-enumPaymentMethod"
                name="enumPaymentMethod"
                data-cy="enumPaymentMethod"
                type="select"
              >
                {enumPaymentMethodValues.map(enumPaymentMethod => (
                  <option value={enumPaymentMethod} key={enumPaymentMethod}>
                    {translate('rentcargwApp.EnumPaymentMethod.' + enumPaymentMethod)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('rentcargwApp.rentcarPaymentTrip.bankName')}
                id="payment-trip-bankName"
                name="bankName"
                data-cy="bankName"
                type="text"
                validate={{
                  maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
                }}
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarPaymentTrip.bankAccount')}
                id="payment-trip-bankAccount"
                name="bankAccount"
                data-cy="bankAccount"
                type="text"
                validate={{
                  maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
                }}
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarPaymentTrip.isPaymentForDeposit')}
                id="payment-trip-isPaymentForDeposit"
                name="isPaymentForDeposit"
                data-cy="isPaymentForDeposit"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 1, message: translate('entity.validation.maxlength', { max: 1 }) },
                }}
              />
              <ValidatedField
                id="payment-trip-tripcar"
                name="tripcar"
                data-cy="tripcar"
                label={translate('rentcargwApp.rentcarPaymentTrip.tripcar')}
                type="select"
              >
                <option value="" key="0" />
                {tripCars
                  ? tripCars.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/payment-trip" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default PaymentTripUpdate;
