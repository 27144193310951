import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import DFeeInsurance from './d-fee-insurance';
import DFeeInsuranceDetail from './d-fee-insurance-detail';
import DFeeInsuranceUpdate from './d-fee-insurance-update';
import DFeeInsuranceDeleteDialog from './d-fee-insurance-delete-dialog';

const DFeeInsuranceRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<DFeeInsurance />} />
    <Route path="new" element={<DFeeInsuranceUpdate />} />
    <Route path=":id">
      <Route index element={<DFeeInsuranceDetail />} />
      <Route path="edit" element={<DFeeInsuranceUpdate />} />
      <Route path="delete" element={<DFeeInsuranceDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default DFeeInsuranceRoutes;
