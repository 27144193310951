import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import TripFeeExtra from './trip-fee-extra';
import TripFeeExtraDetail from './trip-fee-extra-detail';
import TripFeeExtraUpdate from './trip-fee-extra-update';
import TripFeeExtraDeleteDialog from './trip-fee-extra-delete-dialog';

const TripFeeExtraRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<TripFeeExtra />} />
    <Route path="new" element={<TripFeeExtraUpdate />} />
    <Route path=":id">
      <Route index element={<TripFeeExtraDetail />} />
      <Route path="edit" element={<TripFeeExtraUpdate />} />
      <Route path="delete" element={<TripFeeExtraDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default TripFeeExtraRoutes;
