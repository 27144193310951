export enum EnumSeat {
  C4 = '4 chỗ',

  C5 = '5 chỗ',

  C7 = '7 chỗ',

  C16 = '16 chỗ',

  C45 = '45 chỗ',
}
