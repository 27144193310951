import dayjs from 'dayjs';
import { IOwnerCar } from 'app/shared/model/rentcar/owner-car.model';

export interface IDPricePenalty {
  id?: number;
  name?: string;
  limitMinutes?: number | null;
  limitKm?: number | null;
  amtPerKm?: number | null;
  amtMin?: number | null;
  amtMax?: number | null;
  createDate?: string | null;
  createBy?: string | null;
  modifyDate?: string | null;
  modifyBy?: string | null;
  onwercars?: IOwnerCar[] | null;
}

export const defaultValue: Readonly<IDPricePenalty> = {};
