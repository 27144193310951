import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './owner-car.reducer';

export const OwnerCarDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const ownerCarEntity = useAppSelector(state => state.rentcargw.ownerCar.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="ownerCarDetailsHeading">
          <Translate contentKey="rentcargwApp.rentcarOwnerCar.detail.title">OwnerCar</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{ownerCarEntity.id}</dd>
          <dt>
            <span id="numberPlate">
              <Translate contentKey="rentcargwApp.rentcarOwnerCar.numberPlate">Number Plate</Translate>
            </span>
          </dt>
          <dd>{ownerCarEntity.numberPlate}</dd>
          <dt>
            <span id="usernameOwner">
              <Translate contentKey="rentcargwApp.rentcarOwnerCar.usernameOwner">Username Owner</Translate>
            </span>
          </dt>
          <dd>{ownerCarEntity.usernameOwner}</dd>
          <dt>
            <span id="userIdOwner">
              <Translate contentKey="rentcargwApp.rentcarOwnerCar.userIdOwner">User Id Owner</Translate>
            </span>
          </dt>
          <dd>{ownerCarEntity.userIdOwner}</dd>
          <dt>
            <span id="introText">
              <Translate contentKey="rentcargwApp.rentcarOwnerCar.introText">Intro Text</Translate>
            </span>
          </dt>
          <dd>{ownerCarEntity.introText}</dd>
          <dt>
            <span id="note">
              <Translate contentKey="rentcargwApp.rentcarOwnerCar.note">Note</Translate>
            </span>
          </dt>
          <dd>{ownerCarEntity.note}</dd>
          <dt>
            <span id="colorCar">
              <Translate contentKey="rentcargwApp.rentcarOwnerCar.colorCar">Color Car</Translate>
            </span>
          </dt>
          <dd>{ownerCarEntity.colorCar}</dd>
          <dt>
            <span id="gearEnum">
              <Translate contentKey="rentcargwApp.rentcarOwnerCar.gearEnum">Gear Enum</Translate>
            </span>
          </dt>
          <dd>{ownerCarEntity.gearEnum}</dd>
          <dt>
            <span id="dieselType">
              <Translate contentKey="rentcargwApp.rentcarOwnerCar.dieselType">Diesel Type</Translate>
            </span>
          </dt>
          <dd>{ownerCarEntity.dieselType}</dd>
          <dt>
            <span id="seatEnum">
              <Translate contentKey="rentcargwApp.rentcarOwnerCar.seatEnum">Seat Enum</Translate>
            </span>
          </dt>
          <dd>{ownerCarEntity.seatEnum}</dd>
          <dt>
            <span id="yearOfManufactury">
              <Translate contentKey="rentcargwApp.rentcarOwnerCar.yearOfManufactury">Year Of Manufactury</Translate>
            </span>
          </dt>
          <dd>{ownerCarEntity.yearOfManufactury}</dd>
          <dt>
            <span id="addressOfCar">
              <Translate contentKey="rentcargwApp.rentcarOwnerCar.addressOfCar">Address Of Car</Translate>
            </span>
          </dt>
          <dd>{ownerCarEntity.addressOfCar}</dd>
          <dt>
            <span id="xcoordinate">
              <Translate contentKey="rentcargwApp.rentcarOwnerCar.xcoordinate">Xcoordinate</Translate>
            </span>
          </dt>
          <dd>{ownerCarEntity.xcoordinate}</dd>
          <dt>
            <span id="ycoordinate">
              <Translate contentKey="rentcargwApp.rentcarOwnerCar.ycoordinate">Ycoordinate</Translate>
            </span>
          </dt>
          <dd>{ownerCarEntity.ycoordinate}</dd>
          <dt>
            <span id="codeCancel">
              <Translate contentKey="rentcargwApp.rentcarOwnerCar.codeCancel">Code Cancel</Translate>
            </span>
          </dt>
          <dd>{ownerCarEntity.codeCancel}</dd>
          <dt>
            <span id="noteCancel">
              <Translate contentKey="rentcargwApp.rentcarOwnerCar.noteCancel">Note Cancel</Translate>
            </span>
          </dt>
          <dd>{ownerCarEntity.noteCancel}</dd>
          <dt>
            <span id="isActive">
              <Translate contentKey="rentcargwApp.rentcarOwnerCar.isActive">Is Active</Translate>
            </span>
          </dt>
          <dd>{ownerCarEntity.isActive}</dd>
          <dt>
            <span id="createDate">
              <Translate contentKey="rentcargwApp.rentcarOwnerCar.createDate">Create Date</Translate>
            </span>
          </dt>
          <dd>
            {ownerCarEntity.createDate ? <TextFormat value={ownerCarEntity.createDate} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="createBy">
              <Translate contentKey="rentcargwApp.rentcarOwnerCar.createBy">Create By</Translate>
            </span>
          </dt>
          <dd>{ownerCarEntity.createBy}</dd>
          <dt>
            <span id="modifyDate">
              <Translate contentKey="rentcargwApp.rentcarOwnerCar.modifyDate">Modify Date</Translate>
            </span>
          </dt>
          <dd>
            {ownerCarEntity.modifyDate ? <TextFormat value={ownerCarEntity.modifyDate} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="modifyBy">
              <Translate contentKey="rentcargwApp.rentcarOwnerCar.modifyBy">Modify By</Translate>
            </span>
          </dt>
          <dd>{ownerCarEntity.modifyBy}</dd>
          <dt>
            <Translate contentKey="rentcargwApp.rentcarOwnerCar.pricerent">Pricerent</Translate>
          </dt>
          <dd>{ownerCarEntity.pricerent ? ownerCarEntity.pricerent.id : ''}</dd>
          <dt>
            <Translate contentKey="rentcargwApp.rentcarOwnerCar.dmodel">Dmodel</Translate>
          </dt>
          <dd>{ownerCarEntity.dmodel ? ownerCarEntity.dmodel.id : ''}</dd>
          <dt>
            <Translate contentKey="rentcargwApp.rentcarOwnerCar.policy">Policy</Translate>
          </dt>
          <dd>{ownerCarEntity.policy ? ownerCarEntity.policy.id : ''}</dd>
          <dt>
            <Translate contentKey="rentcargwApp.rentcarOwnerCar.dpricepernalty">Dpricepernalty</Translate>
          </dt>
          <dd>{ownerCarEntity.dpricepernalty ? ownerCarEntity.dpricepernalty.id : ''}</dd>
          <dt>
            <Translate contentKey="rentcargwApp.rentcarOwnerCar.dfeeservice">Dfeeservice</Translate>
          </dt>
          <dd>{ownerCarEntity.dfeeservice ? ownerCarEntity.dfeeservice.id : ''}</dd>
          <dt>
            <Translate contentKey="rentcargwApp.rentcarOwnerCar.dfeeinsurance">Dfeeinsurance</Translate>
          </dt>
          <dd>{ownerCarEntity.dfeeinsurance ? ownerCarEntity.dfeeinsurance.id : ''}</dd>
          <dt>
            <Translate contentKey="rentcargwApp.rentcarOwnerCar.dpromotion">Dpromotion</Translate>
          </dt>
          <dd>{ownerCarEntity.dpromotion ? ownerCarEntity.dpromotion.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/owner-car" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/owner-car/${ownerCarEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default OwnerCarDetail;
