import dayjs from 'dayjs';
import { IOwnerCar } from 'app/shared/model/rentcar/owner-car.model';

export interface IDFeeInsurance {
  id?: number;
  feeAmount?: number | null;
  typeFee?: string | null;
  description?: string | null;
  isActive?: string | null;
  createDate?: string | null;
  createBy?: string | null;
  modifyDate?: string | null;
  modifyBy?: string | null;
  onwercars?: IOwnerCar[] | null;
}

export const defaultValue: Readonly<IDFeeInsurance> = {};
