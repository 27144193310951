import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './fee-extra.reducer';

export const FeeExtraDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const feeExtraEntity = useAppSelector(state => state.rentcargw.feeExtra.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="feeExtraDetailsHeading">
          <Translate contentKey="rentcargwApp.rentcarFeeExtra.detail.title">FeeExtra</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{feeExtraEntity.id}</dd>
          <dt>
            <span id="codeExtra">
              <Translate contentKey="rentcargwApp.rentcarFeeExtra.codeExtra">Code Extra</Translate>
            </span>
          </dt>
          <dd>{feeExtraEntity.codeExtra}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="rentcargwApp.rentcarFeeExtra.description">Description</Translate>
            </span>
          </dt>
          <dd>{feeExtraEntity.description}</dd>
          <dt>
            <span id="typeFee">
              <Translate contentKey="rentcargwApp.rentcarFeeExtra.typeFee">Type Fee</Translate>
            </span>
          </dt>
          <dd>{feeExtraEntity.typeFee}</dd>
          <dt>
            <span id="amount">
              <Translate contentKey="rentcargwApp.rentcarFeeExtra.amount">Amount</Translate>
            </span>
          </dt>
          <dd>{feeExtraEntity.amount}</dd>
          <dt>
            <span id="createDate">
              <Translate contentKey="rentcargwApp.rentcarFeeExtra.createDate">Create Date</Translate>
            </span>
          </dt>
          <dd>
            {feeExtraEntity.createDate ? <TextFormat value={feeExtraEntity.createDate} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="createBy">
              <Translate contentKey="rentcargwApp.rentcarFeeExtra.createBy">Create By</Translate>
            </span>
          </dt>
          <dd>{feeExtraEntity.createBy}</dd>
          <dt>
            <span id="modifyDate">
              <Translate contentKey="rentcargwApp.rentcarFeeExtra.modifyDate">Modify Date</Translate>
            </span>
          </dt>
          <dd>
            {feeExtraEntity.modifyDate ? <TextFormat value={feeExtraEntity.modifyDate} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="modifyBy">
              <Translate contentKey="rentcargwApp.rentcarFeeExtra.modifyBy">Modify By</Translate>
            </span>
          </dt>
          <dd>{feeExtraEntity.modifyBy}</dd>
          <dt>
            <Translate contentKey="rentcargwApp.rentcarFeeExtra.onwer">Onwer</Translate>
          </dt>
          <dd>{feeExtraEntity.onwer ? feeExtraEntity.onwer.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/fee-extra" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/fee-extra/${feeExtraEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default FeeExtraDetail;
