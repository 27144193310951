import { IDManufacter } from 'app/shared/model/rentcar/d-manufacter.model';

export interface IDModel {
  id?: number;
  name?: string;
  seqNo?: number | null;
  isActive?: string;
  dmanf?: IDManufacter | null;
}

export const defaultValue: Readonly<IDModel> = {};
