import dayjs from 'dayjs';
import { ITripCar } from 'app/shared/model/rentcar/trip-car.model';

export interface IPhotoBeforeTrip {
  id?: number;
  numberPlate?: string;
  fileName?: string | null;
  pathFile?: string;
  createDate?: string | null;
  createBy?: string | null;
  modifyDate?: string | null;
  modifyBy?: string | null;
  tripcar?: ITripCar | null;
}

export const defaultValue: Readonly<IPhotoBeforeTrip> = {};
