import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import PriceDepositType from './price-deposit-type';
import PriceDepositTypeDetail from './price-deposit-type-detail';
import PriceDepositTypeUpdate from './price-deposit-type-update';
import PriceDepositTypeDeleteDialog from './price-deposit-type-delete-dialog';

const PriceDepositTypeRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<PriceDepositType />} />
    <Route path="new" element={<PriceDepositTypeUpdate />} />
    <Route path=":id">
      <Route index element={<PriceDepositTypeDetail />} />
      <Route path="edit" element={<PriceDepositTypeUpdate />} />
      <Route path="delete" element={<PriceDepositTypeDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default PriceDepositTypeRoutes;
