import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import DManufacter from './d-manufacter';
import DManufacterDetail from './d-manufacter-detail';
import DManufacterUpdate from './d-manufacter-update';
import DManufacterDeleteDialog from './d-manufacter-delete-dialog';

const DManufacterRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<DManufacter />} />
    <Route path="new" element={<DManufacterUpdate />} />
    <Route path=":id">
      <Route index element={<DManufacterDetail />} />
      <Route path="edit" element={<DManufacterUpdate />} />
      <Route path="delete" element={<DManufacterDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default DManufacterRoutes;
