import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import NotificationUser from './notification-user';
import NotificationUserDetail from './notification-user-detail';
import NotificationUserUpdate from './notification-user-update';
import NotificationUserDeleteDialog from './notification-user-delete-dialog';

const NotificationUserRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<NotificationUser />} />
    <Route path="new" element={<NotificationUserUpdate />} />
    <Route path=":id">
      <Route index element={<NotificationUserDetail />} />
      <Route path="edit" element={<NotificationUserUpdate />} />
      <Route path="delete" element={<NotificationUserDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default NotificationUserRoutes;
