import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { ITripCarHistory } from 'app/shared/model/rentcar/trip-car-history.model';
import { EnumStatusTrip } from 'app/shared/model/enumerations/enum-status-trip.model';
import { getEntity, updateEntity, createEntity, reset } from './trip-car-history.reducer';

export const TripCarHistoryUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const tripCarHistoryEntity = useAppSelector(state => state.rentcargw.tripCarHistory.entity);
  const loading = useAppSelector(state => state.rentcargw.tripCarHistory.loading);
  const updating = useAppSelector(state => state.rentcargw.tripCarHistory.updating);
  const updateSuccess = useAppSelector(state => state.rentcargw.tripCarHistory.updateSuccess);
  const enumStatusTripValues = Object.keys(EnumStatusTrip);

  const handleClose = () => {
    navigate('/trip-car-history');
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.startDate = convertDateTimeToServer(values.startDate);
    values.endDate = convertDateTimeToServer(values.endDate);
    values.dateDriverDeparture = convertDateTimeToServer(values.dateDriverDeparture);
    values.dateDriverReturn = convertDateTimeToServer(values.dateDriverReturn);
    values.createDate = convertDateTimeToServer(values.createDate);
    values.modifyDate = convertDateTimeToServer(values.modifyDate);

    const entity = {
      ...tripCarHistoryEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          startDate: displayDefaultDateTime(),
          endDate: displayDefaultDateTime(),
          dateDriverDeparture: displayDefaultDateTime(),
          dateDriverReturn: displayDefaultDateTime(),
          createDate: displayDefaultDateTime(),
          modifyDate: displayDefaultDateTime(),
        }
      : {
          statusTrip: 'WAITAPPROVAL',
          ...tripCarHistoryEntity,
          startDate: convertDateTimeFromServer(tripCarHistoryEntity.startDate),
          endDate: convertDateTimeFromServer(tripCarHistoryEntity.endDate),
          dateDriverDeparture: convertDateTimeFromServer(tripCarHistoryEntity.dateDriverDeparture),
          dateDriverReturn: convertDateTimeFromServer(tripCarHistoryEntity.dateDriverReturn),
          createDate: convertDateTimeFromServer(tripCarHistoryEntity.createDate),
          modifyDate: convertDateTimeFromServer(tripCarHistoryEntity.modifyDate),
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="rentcargwApp.rentcarTripCarHistory.home.createOrEditLabel" data-cy="TripCarHistoryCreateUpdateHeading">
            <Translate contentKey="rentcargwApp.rentcarTripCarHistory.home.createOrEditLabel">Create or edit a TripCarHistory</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="trip-car-history-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCarHistory.codeTrip')}
                id="trip-car-history-codeTrip"
                name="codeTrip"
                data-cy="codeTrip"
                type="text"
                validate={{
                  maxLength: { value: 20, message: translate('entity.validation.maxlength', { max: 20 }) },
                }}
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCarHistory.numberPlate')}
                id="trip-car-history-numberPlate"
                name="numberPlate"
                data-cy="numberPlate"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 20, message: translate('entity.validation.maxlength', { max: 20 }) },
                }}
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCarHistory.usernameRenter')}
                id="trip-car-history-usernameRenter"
                name="usernameRenter"
                data-cy="usernameRenter"
                type="text"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCarHistory.usernameOwner')}
                id="trip-car-history-usernameOwner"
                name="usernameOwner"
                data-cy="usernameOwner"
                type="text"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCarHistory.startDate')}
                id="trip-car-history-startDate"
                name="startDate"
                data-cy="startDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCarHistory.endDate')}
                id="trip-car-history-endDate"
                name="endDate"
                data-cy="endDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCarHistory.dateDriverDeparture')}
                id="trip-car-history-dateDriverDeparture"
                name="dateDriverDeparture"
                data-cy="dateDriverDeparture"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCarHistory.dateDriverReturn')}
                id="trip-car-history-dateDriverReturn"
                name="dateDriverReturn"
                data-cy="dateDriverReturn"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCarHistory.diadiemNhanxe')}
                id="trip-car-history-diadiemNhanxe"
                name="diadiemNhanxe"
                data-cy="diadiemNhanxe"
                type="text"
                validate={{
                  maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
                }}
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCarHistory.limitKm')}
                id="trip-car-history-limitKm"
                name="limitKm"
                data-cy="limitKm"
                type="text"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCarHistory.noteRenter')}
                id="trip-car-history-noteRenter"
                name="noteRenter"
                data-cy="noteRenter"
                type="text"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCarHistory.feePenaltyKm')}
                id="trip-car-history-feePenaltyKm"
                name="feePenaltyKm"
                data-cy="feePenaltyKm"
                type="text"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCarHistory.price')}
                id="trip-car-history-price"
                name="price"
                data-cy="price"
                type="text"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCarHistory.feeSerivceId')}
                id="trip-car-history-feeSerivceId"
                name="feeSerivceId"
                data-cy="feeSerivceId"
                type="text"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCarHistory.amtFeeService')}
                id="trip-car-history-amtFeeService"
                name="amtFeeService"
                data-cy="amtFeeService"
                type="text"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCarHistory.amtFeePercent')}
                id="trip-car-history-amtFeePercent"
                name="amtFeePercent"
                data-cy="amtFeePercent"
                type="text"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCarHistory.feeInsuranceId')}
                id="trip-car-history-feeInsuranceId"
                name="feeInsuranceId"
                data-cy="feeInsuranceId"
                type="text"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCarHistory.amtInsurance')}
                id="trip-car-history-amtInsurance"
                name="amtInsurance"
                data-cy="amtInsurance"
                type="text"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCarHistory.amtInsurancePercent')}
                id="trip-car-history-amtInsurancePercent"
                name="amtInsurancePercent"
                data-cy="amtInsurancePercent"
                type="text"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCarHistory.promotionId')}
                id="trip-car-history-promotionId"
                name="promotionId"
                data-cy="promotionId"
                type="text"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCarHistory.promotionCode')}
                id="trip-car-history-promotionCode"
                name="promotionCode"
                data-cy="promotionCode"
                type="text"
                validate={{
                  maxLength: { value: 30, message: translate('entity.validation.maxlength', { max: 30 }) },
                }}
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCarHistory.amtPromotion')}
                id="trip-car-history-amtPromotion"
                name="amtPromotion"
                data-cy="amtPromotion"
                type="text"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCarHistory.amtDeposit')}
                id="trip-car-history-amtDeposit"
                name="amtDeposit"
                data-cy="amtDeposit"
                type="text"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCarHistory.amtDepositPercent')}
                id="trip-car-history-amtDepositPercent"
                name="amtDepositPercent"
                data-cy="amtDepositPercent"
                type="text"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCarHistory.pricePenaltyId')}
                id="trip-car-history-pricePenaltyId"
                name="pricePenaltyId"
                data-cy="pricePenaltyId"
                type="text"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCarHistory.amtFeePenaltyService')}
                id="trip-car-history-amtFeePenaltyService"
                name="amtFeePenaltyService"
                data-cy="amtFeePenaltyService"
                type="text"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCarHistory.amtFeePenaltyPercent')}
                id="trip-car-history-amtFeePenaltyPercent"
                name="amtFeePenaltyPercent"
                data-cy="amtFeePenaltyPercent"
                type="text"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCarHistory.totalAmt')}
                id="trip-car-history-totalAmt"
                name="totalAmt"
                data-cy="totalAmt"
                type="text"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCarHistory.codeCancel')}
                id="trip-car-history-codeCancel"
                name="codeCancel"
                data-cy="codeCancel"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCarHistory.noteCancel')}
                id="trip-car-history-noteCancel"
                name="noteCancel"
                data-cy="noteCancel"
                type="text"
                validate={{
                  maxLength: { value: 500, message: translate('entity.validation.maxlength', { max: 500 }) },
                }}
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCarHistory.statusTrip')}
                id="trip-car-history-statusTrip"
                name="statusTrip"
                data-cy="statusTrip"
                type="select"
              >
                {enumStatusTripValues.map(enumStatusTrip => (
                  <option value={enumStatusTrip} key={enumStatusTrip}>
                    {translate('rentcargwApp.EnumStatusTrip.' + enumStatusTrip)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCarHistory.createDate')}
                id="trip-car-history-createDate"
                name="createDate"
                data-cy="createDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCarHistory.createBy')}
                id="trip-car-history-createBy"
                name="createBy"
                data-cy="createBy"
                type="text"
                validate={{
                  maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                }}
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCarHistory.modifyDate')}
                id="trip-car-history-modifyDate"
                name="modifyDate"
                data-cy="modifyDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCarHistory.modifyBy')}
                id="trip-car-history-modifyBy"
                name="modifyBy"
                data-cy="modifyBy"
                type="text"
                validate={{
                  maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                }}
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/trip-car-history" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default TripCarHistoryUpdate;
