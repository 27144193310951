import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './user-favourite.reducer';

export const UserFavouriteDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const userFavouriteEntity = useAppSelector(state => state.rentcargw.userFavourite.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="userFavouriteDetailsHeading">
          <Translate contentKey="rentcargwApp.rentcarUserFavourite.detail.title">UserFavourite</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{userFavouriteEntity.id}</dd>
          <dt>
            <span id="numberPlate">
              <Translate contentKey="rentcargwApp.rentcarUserFavourite.numberPlate">Number Plate</Translate>
            </span>
          </dt>
          <dd>{userFavouriteEntity.numberPlate}</dd>
          <dt>
            <span id="usernameRenter">
              <Translate contentKey="rentcargwApp.rentcarUserFavourite.usernameRenter">Username Renter</Translate>
            </span>
          </dt>
          <dd>{userFavouriteEntity.usernameRenter}</dd>
          <dt>
            <span id="createDate">
              <Translate contentKey="rentcargwApp.rentcarUserFavourite.createDate">Create Date</Translate>
            </span>
          </dt>
          <dd>
            {userFavouriteEntity.createDate ? (
              <TextFormat value={userFavouriteEntity.createDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="createBy">
              <Translate contentKey="rentcargwApp.rentcarUserFavourite.createBy">Create By</Translate>
            </span>
          </dt>
          <dd>{userFavouriteEntity.createBy}</dd>
          <dt>
            <span id="modifyDate">
              <Translate contentKey="rentcargwApp.rentcarUserFavourite.modifyDate">Modify Date</Translate>
            </span>
          </dt>
          <dd>
            {userFavouriteEntity.modifyDate ? (
              <TextFormat value={userFavouriteEntity.modifyDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="modifyBy">
              <Translate contentKey="rentcargwApp.rentcarUserFavourite.modifyBy">Modify By</Translate>
            </span>
          </dt>
          <dd>{userFavouriteEntity.modifyBy}</dd>
          <dt>
            <Translate contentKey="rentcargwApp.rentcarUserFavourite.owner">Owner</Translate>
          </dt>
          <dd>{userFavouriteEntity.owner ? userFavouriteEntity.owner.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/user-favourite" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/user-favourite/${userFavouriteEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default UserFavouriteDetail;
