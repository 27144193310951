export enum EnumStatusTrip {
  WAITAPPROVAL = 'WAITAPPROVAL',

  AUTOAPPROVED = 'AUTOAPPROVED',

  DENIED = 'DENIED',

  WAITDEPOSIT = 'WAITDEPOSIT',

  CONFIRMED_DEPOSITED_STARTTRIP = 'CONFIRMED_DEPOSITED_STARTTRIP',

  DEPOSITED = 'DEPOSITED',

  CANCELED = 'CANCELED',

  STARTED = 'STARTED',

  COMPLETED = 'COMPLETED',
}
