import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { ITripCarHistory } from 'app/shared/model/rentcar/trip-car-history.model';
import { getEntities } from './trip-car-history.reducer';

export const TripCarHistory = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const tripCarHistoryList = useAppSelector(state => state.rentcargw.tripCarHistory.entities);
  const loading = useAppSelector(state => state.rentcargw.tripCarHistory.loading);

  useEffect(() => {
    dispatch(getEntities({}));
  }, []);

  const handleSyncList = () => {
    dispatch(getEntities({}));
  };

  return (
    <div>
      <h2 id="trip-car-history-heading" data-cy="TripCarHistoryHeading">
        <Translate contentKey="rentcargwApp.rentcarTripCarHistory.home.title">Trip Car Histories</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="rentcargwApp.rentcarTripCarHistory.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link to="/trip-car-history/new" className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="rentcargwApp.rentcarTripCarHistory.home.createLabel">Create new Trip Car History</Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        {tripCarHistoryList && tripCarHistoryList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th>
                  <Translate contentKey="rentcargwApp.rentcarTripCarHistory.id">ID</Translate>
                </th>
                <th>
                  <Translate contentKey="rentcargwApp.rentcarTripCarHistory.codeTrip">Code Trip</Translate>
                </th>
                <th>
                  <Translate contentKey="rentcargwApp.rentcarTripCarHistory.numberPlate">Number Plate</Translate>
                </th>
                <th>
                  <Translate contentKey="rentcargwApp.rentcarTripCarHistory.usernameRenter">Username Renter</Translate>
                </th>
                <th>
                  <Translate contentKey="rentcargwApp.rentcarTripCarHistory.usernameOwner">Username Owner</Translate>
                </th>
                <th>
                  <Translate contentKey="rentcargwApp.rentcarTripCarHistory.startDate">Start Date</Translate>
                </th>
                <th>
                  <Translate contentKey="rentcargwApp.rentcarTripCarHistory.endDate">End Date</Translate>
                </th>
                <th>
                  <Translate contentKey="rentcargwApp.rentcarTripCarHistory.dateDriverDeparture">Date Driver Departure</Translate>
                </th>
                <th>
                  <Translate contentKey="rentcargwApp.rentcarTripCarHistory.dateDriverReturn">Date Driver Return</Translate>
                </th>
                <th>
                  <Translate contentKey="rentcargwApp.rentcarTripCarHistory.diadiemNhanxe">Diadiem Nhanxe</Translate>
                </th>
                <th>
                  <Translate contentKey="rentcargwApp.rentcarTripCarHistory.limitKm">Limit Km</Translate>
                </th>
                <th>
                  <Translate contentKey="rentcargwApp.rentcarTripCarHistory.noteRenter">Note Renter</Translate>
                </th>
                <th>
                  <Translate contentKey="rentcargwApp.rentcarTripCarHistory.feePenaltyKm">Fee Penalty Km</Translate>
                </th>
                <th>
                  <Translate contentKey="rentcargwApp.rentcarTripCarHistory.price">Price</Translate>
                </th>
                <th>
                  <Translate contentKey="rentcargwApp.rentcarTripCarHistory.feeSerivceId">Fee Serivce Id</Translate>
                </th>
                <th>
                  <Translate contentKey="rentcargwApp.rentcarTripCarHistory.amtFeeService">Amt Fee Service</Translate>
                </th>
                <th>
                  <Translate contentKey="rentcargwApp.rentcarTripCarHistory.amtFeePercent">Amt Fee Percent</Translate>
                </th>
                <th>
                  <Translate contentKey="rentcargwApp.rentcarTripCarHistory.feeInsuranceId">Fee Insurance Id</Translate>
                </th>
                <th>
                  <Translate contentKey="rentcargwApp.rentcarTripCarHistory.amtInsurance">Amt Insurance</Translate>
                </th>
                <th>
                  <Translate contentKey="rentcargwApp.rentcarTripCarHistory.amtInsurancePercent">Amt Insurance Percent</Translate>
                </th>
                <th>
                  <Translate contentKey="rentcargwApp.rentcarTripCarHistory.promotionId">Promotion Id</Translate>
                </th>
                <th>
                  <Translate contentKey="rentcargwApp.rentcarTripCarHistory.promotionCode">Promotion Code</Translate>
                </th>
                <th>
                  <Translate contentKey="rentcargwApp.rentcarTripCarHistory.amtPromotion">Amt Promotion</Translate>
                </th>
                <th>
                  <Translate contentKey="rentcargwApp.rentcarTripCarHistory.amtDeposit">Amt Deposit</Translate>
                </th>
                <th>
                  <Translate contentKey="rentcargwApp.rentcarTripCarHistory.amtDepositPercent">Amt Deposit Percent</Translate>
                </th>
                <th>
                  <Translate contentKey="rentcargwApp.rentcarTripCarHistory.pricePenaltyId">Price Penalty Id</Translate>
                </th>
                <th>
                  <Translate contentKey="rentcargwApp.rentcarTripCarHistory.amtFeePenaltyService">Amt Fee Penalty Service</Translate>
                </th>
                <th>
                  <Translate contentKey="rentcargwApp.rentcarTripCarHistory.amtFeePenaltyPercent">Amt Fee Penalty Percent</Translate>
                </th>
                <th>
                  <Translate contentKey="rentcargwApp.rentcarTripCarHistory.totalAmt">Total Amt</Translate>
                </th>
                <th>
                  <Translate contentKey="rentcargwApp.rentcarTripCarHistory.codeCancel">Code Cancel</Translate>
                </th>
                <th>
                  <Translate contentKey="rentcargwApp.rentcarTripCarHistory.noteCancel">Note Cancel</Translate>
                </th>
                <th>
                  <Translate contentKey="rentcargwApp.rentcarTripCarHistory.statusTrip">Status Trip</Translate>
                </th>
                <th>
                  <Translate contentKey="rentcargwApp.rentcarTripCarHistory.createDate">Create Date</Translate>
                </th>
                <th>
                  <Translate contentKey="rentcargwApp.rentcarTripCarHistory.createBy">Create By</Translate>
                </th>
                <th>
                  <Translate contentKey="rentcargwApp.rentcarTripCarHistory.modifyDate">Modify Date</Translate>
                </th>
                <th>
                  <Translate contentKey="rentcargwApp.rentcarTripCarHistory.modifyBy">Modify By</Translate>
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {tripCarHistoryList.map((tripCarHistory, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`/trip-car-history/${tripCarHistory.id}`} color="link" size="sm">
                      {tripCarHistory.id}
                    </Button>
                  </td>
                  <td>{tripCarHistory.codeTrip}</td>
                  <td>{tripCarHistory.numberPlate}</td>
                  <td>{tripCarHistory.usernameRenter}</td>
                  <td>{tripCarHistory.usernameOwner}</td>
                  <td>
                    {tripCarHistory.startDate ? <TextFormat type="date" value={tripCarHistory.startDate} format={APP_DATE_FORMAT} /> : null}
                  </td>
                  <td>
                    {tripCarHistory.endDate ? <TextFormat type="date" value={tripCarHistory.endDate} format={APP_DATE_FORMAT} /> : null}
                  </td>
                  <td>
                    {tripCarHistory.dateDriverDeparture ? (
                      <TextFormat type="date" value={tripCarHistory.dateDriverDeparture} format={APP_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>
                    {tripCarHistory.dateDriverReturn ? (
                      <TextFormat type="date" value={tripCarHistory.dateDriverReturn} format={APP_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>{tripCarHistory.diadiemNhanxe}</td>
                  <td>{tripCarHistory.limitKm}</td>
                  <td>{tripCarHistory.noteRenter}</td>
                  <td>{tripCarHistory.feePenaltyKm}</td>
                  <td>{tripCarHistory.price}</td>
                  <td>{tripCarHistory.feeSerivceId}</td>
                  <td>{tripCarHistory.amtFeeService}</td>
                  <td>{tripCarHistory.amtFeePercent}</td>
                  <td>{tripCarHistory.feeInsuranceId}</td>
                  <td>{tripCarHistory.amtInsurance}</td>
                  <td>{tripCarHistory.amtInsurancePercent}</td>
                  <td>{tripCarHistory.promotionId}</td>
                  <td>{tripCarHistory.promotionCode}</td>
                  <td>{tripCarHistory.amtPromotion}</td>
                  <td>{tripCarHistory.amtDeposit}</td>
                  <td>{tripCarHistory.amtDepositPercent}</td>
                  <td>{tripCarHistory.pricePenaltyId}</td>
                  <td>{tripCarHistory.amtFeePenaltyService}</td>
                  <td>{tripCarHistory.amtFeePenaltyPercent}</td>
                  <td>{tripCarHistory.totalAmt}</td>
                  <td>{tripCarHistory.codeCancel}</td>
                  <td>{tripCarHistory.noteCancel}</td>
                  <td>
                    <Translate contentKey={`rentcargwApp.EnumStatusTrip.${tripCarHistory.statusTrip}`} />
                  </td>
                  <td>
                    {tripCarHistory.createDate ? (
                      <TextFormat type="date" value={tripCarHistory.createDate} format={APP_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>{tripCarHistory.createBy}</td>
                  <td>
                    {tripCarHistory.modifyDate ? (
                      <TextFormat type="date" value={tripCarHistory.modifyDate} format={APP_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>{tripCarHistory.modifyBy}</td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`/trip-car-history/${tripCarHistory.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/trip-car-history/${tripCarHistory.id}/edit`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/trip-car-history/${tripCarHistory.id}/delete`}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="rentcargwApp.rentcarTripCarHistory.home.notFound">No Trip Car Histories found</Translate>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default TripCarHistory;
