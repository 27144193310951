import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './trip-feature-extra.reducer';

export const TripFeatureExtraDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const tripFeatureExtraEntity = useAppSelector(state => state.rentcargw.tripFeatureExtra.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="tripFeatureExtraDetailsHeading">
          <Translate contentKey="rentcargwApp.rentcarTripFeatureExtra.detail.title">TripFeatureExtra</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{tripFeatureExtraEntity.id}</dd>
          <dt>
            <span id="codeExtra">
              <Translate contentKey="rentcargwApp.rentcarTripFeatureExtra.codeExtra">Code Extra</Translate>
            </span>
          </dt>
          <dd>{tripFeatureExtraEntity.codeExtra}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="rentcargwApp.rentcarTripFeatureExtra.description">Description</Translate>
            </span>
          </dt>
          <dd>{tripFeatureExtraEntity.description}</dd>
          <dt>
            <span id="data">
              <Translate contentKey="rentcargwApp.rentcarTripFeatureExtra.data">Data</Translate>
            </span>
          </dt>
          <dd>{tripFeatureExtraEntity.data}</dd>
          <dt>
            <span id="createDate">
              <Translate contentKey="rentcargwApp.rentcarTripFeatureExtra.createDate">Create Date</Translate>
            </span>
          </dt>
          <dd>
            {tripFeatureExtraEntity.createDate ? (
              <TextFormat value={tripFeatureExtraEntity.createDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="createBy">
              <Translate contentKey="rentcargwApp.rentcarTripFeatureExtra.createBy">Create By</Translate>
            </span>
          </dt>
          <dd>{tripFeatureExtraEntity.createBy}</dd>
          <dt>
            <span id="modifyDate">
              <Translate contentKey="rentcargwApp.rentcarTripFeatureExtra.modifyDate">Modify Date</Translate>
            </span>
          </dt>
          <dd>
            {tripFeatureExtraEntity.modifyDate ? (
              <TextFormat value={tripFeatureExtraEntity.modifyDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="modifyBy">
              <Translate contentKey="rentcargwApp.rentcarTripFeatureExtra.modifyBy">Modify By</Translate>
            </span>
          </dt>
          <dd>{tripFeatureExtraEntity.modifyBy}</dd>
          <dt>
            <Translate contentKey="rentcargwApp.rentcarTripFeatureExtra.tripcar">Tripcar</Translate>
          </dt>
          <dd>{tripFeatureExtraEntity.tripcar ? tripFeatureExtraEntity.tripcar.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/trip-feature-extra" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/trip-feature-extra/${tripFeatureExtraEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default TripFeatureExtraDetail;
