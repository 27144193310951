import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './payment-trip.reducer';

export const PaymentTripDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const paymentTripEntity = useAppSelector(state => state.rentcargw.paymentTrip.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="paymentTripDetailsHeading">
          <Translate contentKey="rentcargwApp.rentcarPaymentTrip.detail.title">PaymentTrip</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{paymentTripEntity.id}</dd>
          <dt>
            <span id="numberPlate">
              <Translate contentKey="rentcargwApp.rentcarPaymentTrip.numberPlate">Number Plate</Translate>
            </span>
          </dt>
          <dd>{paymentTripEntity.numberPlate}</dd>
          <dt>
            <span id="amount">
              <Translate contentKey="rentcargwApp.rentcarPaymentTrip.amount">Amount</Translate>
            </span>
          </dt>
          <dd>{paymentTripEntity.amount}</dd>
          <dt>
            <span id="enumPaymentMethod">
              <Translate contentKey="rentcargwApp.rentcarPaymentTrip.enumPaymentMethod">Enum Payment Method</Translate>
            </span>
          </dt>
          <dd>{paymentTripEntity.enumPaymentMethod}</dd>
          <dt>
            <span id="bankName">
              <Translate contentKey="rentcargwApp.rentcarPaymentTrip.bankName">Bank Name</Translate>
            </span>
          </dt>
          <dd>{paymentTripEntity.bankName}</dd>
          <dt>
            <span id="bankAccount">
              <Translate contentKey="rentcargwApp.rentcarPaymentTrip.bankAccount">Bank Account</Translate>
            </span>
          </dt>
          <dd>{paymentTripEntity.bankAccount}</dd>
          <dt>
            <span id="isPaymentForDeposit">
              <Translate contentKey="rentcargwApp.rentcarPaymentTrip.isPaymentForDeposit">Is Payment For Deposit</Translate>
            </span>
          </dt>
          <dd>{paymentTripEntity.isPaymentForDeposit}</dd>
          <dt>
            <Translate contentKey="rentcargwApp.rentcarPaymentTrip.tripcar">Tripcar</Translate>
          </dt>
          <dd>{paymentTripEntity.tripcar ? paymentTripEntity.tripcar.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/payment-trip" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/payment-trip/${paymentTripEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default PaymentTripDetail;
