import { ITripCar } from 'app/shared/model/rentcar/trip-car.model';
import { EnumPaymentMethod } from 'app/shared/model/enumerations/enum-payment-method.model';

export interface IPaymentTrip {
  id?: number;
  numberPlate?: string | null;
  amount?: number | null;
  enumPaymentMethod?: EnumPaymentMethod | null;
  bankName?: string | null;
  bankAccount?: string | null;
  isPaymentForDeposit?: string;
  tripcar?: ITripCar | null;
}

export const defaultValue: Readonly<IPaymentTrip> = {};
