import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IOwnerCar } from 'app/shared/model/rentcar/owner-car.model';
import { getEntities as getOwnerCars } from 'app/entities/rentcar/owner-car/owner-car.reducer';
import { ITripCar } from 'app/shared/model/rentcar/trip-car.model';
import { EnumStatusTrip } from 'app/shared/model/enumerations/enum-status-trip.model';
import { getEntity, updateEntity, createEntity, reset } from './trip-car.reducer';

export const TripCarUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const ownerCars = useAppSelector(state => state.rentcargw.ownerCar.entities);
  const tripCarEntity = useAppSelector(state => state.rentcargw.tripCar.entity);
  const loading = useAppSelector(state => state.rentcargw.tripCar.loading);
  const updating = useAppSelector(state => state.rentcargw.tripCar.updating);
  const updateSuccess = useAppSelector(state => state.rentcargw.tripCar.updateSuccess);
  const enumStatusTripValues = Object.keys(EnumStatusTrip);

  const handleClose = () => {
    navigate('/trip-car' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getOwnerCars({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.startDate = convertDateTimeToServer(values.startDate);
    values.endDate = convertDateTimeToServer(values.endDate);
    values.dateDriverDeparture = convertDateTimeToServer(values.dateDriverDeparture);
    values.dateDriverReturn = convertDateTimeToServer(values.dateDriverReturn);
    values.createDate = convertDateTimeToServer(values.createDate);
    values.modifyDate = convertDateTimeToServer(values.modifyDate);

    const entity = {
      ...tripCarEntity,
      ...values,
      ownercar: ownerCars.find(it => it.id.toString() === values.ownercar.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          startDate: displayDefaultDateTime(),
          endDate: displayDefaultDateTime(),
          dateDriverDeparture: displayDefaultDateTime(),
          dateDriverReturn: displayDefaultDateTime(),
          createDate: displayDefaultDateTime(),
          modifyDate: displayDefaultDateTime(),
        }
      : {
          statusTrip: 'WAITAPPROVAL',
          ...tripCarEntity,
          startDate: convertDateTimeFromServer(tripCarEntity.startDate),
          endDate: convertDateTimeFromServer(tripCarEntity.endDate),
          dateDriverDeparture: convertDateTimeFromServer(tripCarEntity.dateDriverDeparture),
          dateDriverReturn: convertDateTimeFromServer(tripCarEntity.dateDriverReturn),
          createDate: convertDateTimeFromServer(tripCarEntity.createDate),
          modifyDate: convertDateTimeFromServer(tripCarEntity.modifyDate),
          ownercar: tripCarEntity?.ownercar?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="rentcargwApp.rentcarTripCar.home.createOrEditLabel" data-cy="TripCarCreateUpdateHeading">
            <Translate contentKey="rentcargwApp.rentcarTripCar.home.createOrEditLabel">Create or edit a TripCar</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="trip-car-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCar.codeTrip')}
                id="trip-car-codeTrip"
                name="codeTrip"
                data-cy="codeTrip"
                type="text"
                validate={{
                  maxLength: { value: 20, message: translate('entity.validation.maxlength', { max: 20 }) },
                }}
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCar.numberPlate')}
                id="trip-car-numberPlate"
                name="numberPlate"
                data-cy="numberPlate"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 20, message: translate('entity.validation.maxlength', { max: 20 }) },
                }}
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCar.usernameRenter')}
                id="trip-car-usernameRenter"
                name="usernameRenter"
                data-cy="usernameRenter"
                type="text"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCar.usernameOwner')}
                id="trip-car-usernameOwner"
                name="usernameOwner"
                data-cy="usernameOwner"
                type="text"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCar.startDate')}
                id="trip-car-startDate"
                name="startDate"
                data-cy="startDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCar.endDate')}
                id="trip-car-endDate"
                name="endDate"
                data-cy="endDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCar.dateDriverDeparture')}
                id="trip-car-dateDriverDeparture"
                name="dateDriverDeparture"
                data-cy="dateDriverDeparture"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCar.dateDriverReturn')}
                id="trip-car-dateDriverReturn"
                name="dateDriverReturn"
                data-cy="dateDriverReturn"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCar.diadiemNhanxe')}
                id="trip-car-diadiemNhanxe"
                name="diadiemNhanxe"
                data-cy="diadiemNhanxe"
                type="text"
                validate={{
                  maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
                }}
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCar.limitKm')}
                id="trip-car-limitKm"
                name="limitKm"
                data-cy="limitKm"
                type="text"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCar.noteRenter')}
                id="trip-car-noteRenter"
                name="noteRenter"
                data-cy="noteRenter"
                type="text"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCar.feePenaltyKm')}
                id="trip-car-feePenaltyKm"
                name="feePenaltyKm"
                data-cy="feePenaltyKm"
                type="text"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCar.price')}
                id="trip-car-price"
                name="price"
                data-cy="price"
                type="text"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCar.feeSerivceId')}
                id="trip-car-feeSerivceId"
                name="feeSerivceId"
                data-cy="feeSerivceId"
                type="text"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCar.amtFeeService')}
                id="trip-car-amtFeeService"
                name="amtFeeService"
                data-cy="amtFeeService"
                type="text"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCar.amtFeePercent')}
                id="trip-car-amtFeePercent"
                name="amtFeePercent"
                data-cy="amtFeePercent"
                type="text"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCar.feeInsuranceId')}
                id="trip-car-feeInsuranceId"
                name="feeInsuranceId"
                data-cy="feeInsuranceId"
                type="text"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCar.amtInsurance')}
                id="trip-car-amtInsurance"
                name="amtInsurance"
                data-cy="amtInsurance"
                type="text"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCar.amtInsurancePercent')}
                id="trip-car-amtInsurancePercent"
                name="amtInsurancePercent"
                data-cy="amtInsurancePercent"
                type="text"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCar.promotionId')}
                id="trip-car-promotionId"
                name="promotionId"
                data-cy="promotionId"
                type="text"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCar.promotionCode')}
                id="trip-car-promotionCode"
                name="promotionCode"
                data-cy="promotionCode"
                type="text"
                validate={{
                  maxLength: { value: 30, message: translate('entity.validation.maxlength', { max: 30 }) },
                }}
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCar.amtPromotion')}
                id="trip-car-amtPromotion"
                name="amtPromotion"
                data-cy="amtPromotion"
                type="text"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCar.amtDeposit')}
                id="trip-car-amtDeposit"
                name="amtDeposit"
                data-cy="amtDeposit"
                type="text"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCar.amtDepositPercent')}
                id="trip-car-amtDepositPercent"
                name="amtDepositPercent"
                data-cy="amtDepositPercent"
                type="text"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCar.pricePenaltyId')}
                id="trip-car-pricePenaltyId"
                name="pricePenaltyId"
                data-cy="pricePenaltyId"
                type="text"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCar.amtFeePenaltyService')}
                id="trip-car-amtFeePenaltyService"
                name="amtFeePenaltyService"
                data-cy="amtFeePenaltyService"
                type="text"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCar.amtFeePenaltyPercent')}
                id="trip-car-amtFeePenaltyPercent"
                name="amtFeePenaltyPercent"
                data-cy="amtFeePenaltyPercent"
                type="text"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCar.totalAmt')}
                id="trip-car-totalAmt"
                name="totalAmt"
                data-cy="totalAmt"
                type="text"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCar.codeCancel')}
                id="trip-car-codeCancel"
                name="codeCancel"
                data-cy="codeCancel"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCar.noteCancel')}
                id="trip-car-noteCancel"
                name="noteCancel"
                data-cy="noteCancel"
                type="text"
                validate={{
                  maxLength: { value: 500, message: translate('entity.validation.maxlength', { max: 500 }) },
                }}
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCar.statusTrip')}
                id="trip-car-statusTrip"
                name="statusTrip"
                data-cy="statusTrip"
                type="select"
              >
                {enumStatusTripValues.map(enumStatusTrip => (
                  <option value={enumStatusTrip} key={enumStatusTrip}>
                    {translate('rentcargwApp.EnumStatusTrip.' + enumStatusTrip)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCar.createDate')}
                id="trip-car-createDate"
                name="createDate"
                data-cy="createDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCar.createBy')}
                id="trip-car-createBy"
                name="createBy"
                data-cy="createBy"
                type="text"
                validate={{
                  maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                }}
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCar.modifyDate')}
                id="trip-car-modifyDate"
                name="modifyDate"
                data-cy="modifyDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('rentcargwApp.rentcarTripCar.modifyBy')}
                id="trip-car-modifyBy"
                name="modifyBy"
                data-cy="modifyBy"
                type="text"
                validate={{
                  maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                }}
              />
              <ValidatedField
                id="trip-car-ownercar"
                name="ownercar"
                data-cy="ownercar"
                label={translate('rentcargwApp.rentcarTripCar.ownercar')}
                type="select"
              >
                <option value="" key="0" />
                {ownerCars
                  ? ownerCars.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/trip-car" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default TripCarUpdate;
