import dayjs from 'dayjs';
import { IPriceRent } from 'app/shared/model/rentcar/price-rent.model';
import { IUserFavourite } from 'app/shared/model/rentcar/user-favourite.model';
import { ITripCar } from 'app/shared/model/rentcar/trip-car.model';
import { IPhoto } from 'app/shared/model/rentcar/photo.model';
import { IFeeExtra } from 'app/shared/model/rentcar/fee-extra.model';
import { IFeatureExtra } from 'app/shared/model/rentcar/feature-extra.model';
import { IDModel } from 'app/shared/model/rentcar/d-model.model';
import { IDPolicy } from 'app/shared/model/rentcar/d-policy.model';
import { IDPricePenalty } from 'app/shared/model/rentcar/d-price-penalty.model';
import { IDFeeService } from 'app/shared/model/rentcar/d-fee-service.model';
import { IDFeeInsurance } from 'app/shared/model/rentcar/d-fee-insurance.model';
import { IDPromotion } from 'app/shared/model/rentcar/d-promotion.model';
import { EnumGearBox } from 'app/shared/model/enumerations/enum-gear-box.model';
import { EnumSeat } from 'app/shared/model/enumerations/enum-seat.model';

export interface IOwnerCar {
  id?: number;
  numberPlate?: string | null;
  usernameOwner?: string | null;
  userIdOwner?: string | null;
  introText?: string | null;
  note?: string | null;
  colorCar?: string | null;
  gearEnum?: EnumGearBox | null;
  dieselType?: string | null;
  seatEnum?: EnumSeat | null;
  yearOfManufactury?: number | null;
  addressOfCar?: string | null;
  xcoordinate?: string | null;
  ycoordinate?: string | null;
  codeCancel?: string | null;
  noteCancel?: string | null;
  isActive?: string;
  createDate?: string | null;
  createBy?: string | null;
  modifyDate?: string | null;
  modifyBy?: string | null;
  pricerent?: IPriceRent | null;
  userfavs?: IUserFavourite[] | null;
  tripcars?: ITripCar[] | null;
  photos?: IPhoto[] | null;
  feeextras?: IFeeExtra[] | null;
  featureextras?: IFeatureExtra[] | null;
  dmodel?: IDModel | null;
  policy?: IDPolicy | null;
  dpricepernalty?: IDPricePenalty | null;
  dfeeservice?: IDFeeService | null;
  dfeeinsurance?: IDFeeInsurance | null;
  dpromotion?: IDPromotion | null;
}

export const defaultValue: Readonly<IOwnerCar> = {};
